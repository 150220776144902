import React, { useEffect, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";

import { useApi } from "../../../hooks";
import { subscribeToProduct, getStripeConfig } from "../../../api/apiEndpoints";
import PaymentForm from "../PaymentForm";
import { setClientSecretStripe } from "../../../slices/appSlice";

const Payment = () => {
  const [stripePromise, setStripePromise] = useState<any>(null);

  const { selectedProduct, membershipUpgraded, clientSecretStripe } =
    useSelector((state: RootState) => state.appData);
  const dispatch = useDispatch();
  const { idToken } = useSelector((state: RootState) => state.auth);

  const {
    request: getStripeConfigReq,
    data: getStripeConfigData,
    error: getStripeConfigDataError,
  } = useApi<any>({
    apiFunc: getStripeConfig,
  });

  const {
    request: subscribeToProductReq,
    data: subscribeToProductData,
    error: subscribeToProductError,
  } = useApi<any>({
    apiFunc: subscribeToProduct,
  });

  useEffect(() => {
    if (idToken && selectedProduct && !membershipUpgraded) {
      subscribeToProductReq(selectedProduct?.businessProductId);
    }
  }, [idToken, selectedProduct]);

  useEffect(() => {
    const promise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string
    );
    setStripePromise(promise);
    if (subscribeToProductData?.clientSecret)
      dispatch(setClientSecretStripe(subscribeToProductData?.clientSecret));
  }, [getStripeConfigData, subscribeToProductData]);

  return (
    <div>
      {stripePromise && clientSecretStripe && (
        <Elements
          stripe={stripePromise}
          options={{
            loader: "always",
            clientSecret: clientSecretStripe,
            appearance: {
              theme: "night",
            },
          }}
        >
          <PaymentForm />
        </Elements>
      )}
    </div>
  );
};

export default Payment;
