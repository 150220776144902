import React from "react";

import { YellowCheckIcon } from "../../assets/images";
import {
  Container,
  Icon,
  TextContainer,
  Title,
  SubTitle,
  Button,
  IconRight,
  TitleTwo,
} from "./styles";

interface DashboardMenuBadgeProps {
  leftIcon?: string;
  title?: string;
  subtitle?: string;
  rightCheckIcon?: boolean;
  rightButton?: boolean;
  rightButtonText?: string;
  marginTop?: string;
  secondaryTitle?: string;
  secondaryTitleColor?: string;
  handleButtonClick?: () => void;
  handleClickTitleTwo?: () => void;
}

const DashboardMenuBadge = ({
  leftIcon,
  title,
  subtitle,
  rightCheckIcon,
  rightButton,
  rightButtonText,
  marginTop,
  secondaryTitle,
  secondaryTitleColor = "white",
  handleButtonClick,
  handleClickTitleTwo,
}: DashboardMenuBadgeProps) => {
  return (
    <Container marginTop={marginTop} onClick={handleClickTitleTwo}>
      {leftIcon && <Icon src={leftIcon} alt="leftIcon" />}
      <TextContainer>
        <Title>{title}</Title>
        {!!subtitle && <SubTitle>{subtitle}</SubTitle>}
      </TextContainer>
      {rightCheckIcon && (
        <IconRight src={YellowCheckIcon} alt="yellowCheckIcon" />
      )}
      {rightButton && (
        <Button onClick={handleButtonClick}>{rightButtonText}</Button>
      )}
      <TitleTwo secondaryTitleColor={secondaryTitleColor}>
        {secondaryTitle}
      </TitleTwo>
    </Container>
  );
};

export default DashboardMenuBadge;
