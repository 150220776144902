import React from "react";

import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import Navbar from "../../features/app/Navbar";
import BurgerMenu from "../EOMBurgerMenu";
import { isTabletOrMobileSize } from "../../utils/queries";
import { Container } from "./styles";

interface NavbarProps {
  dashboardScreen: boolean;
  checkoutScreen?: boolean;
}

const NavbarContainer = ({
  dashboardScreen,
  checkoutScreen = false,
}: NavbarProps) => {
  const { loggedIn } = useSelector((state: RootState) => state.auth);
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  const mobileNotLoginUser = !loggedIn && isTabletOrMobile;
  return (
    <Container>
      <Navbar
        dashboardScreen={dashboardScreen}
        checkoutScreen={checkoutScreen}
      />
      {mobileNotLoginUser && !checkoutScreen && (
        <BurgerMenu size={20} color={"white"} />
      )}
    </Container>
  );
};

export default NavbarContainer;
