import React from "react";
import { v4 as uuidv4 } from "uuid";
import { HiCheck } from "react-icons/hi";

import {
  BigThreeIconY,
  ColdPlungeIconY,
  GroupClasessIconY,
  LockersIconY,
  NutritionBarIconY,
  PersonalTrainingY,
  RedLightIconY,
  SaunaIconY,
  ShowersIconY,
  SpinningIconY,
  SteamRoomIconY,
  SwimmingPoolIconY,
  TanningIconY,
  TwentyFourOpenY,
} from "../../assets/images";
import { Image, Container, SubContainer, Tile, BoxContainer } from "./styles";

interface AmenitiesIconProps {
  amenities: string[];
}

const iconMapping: Record<string, string> = {
  "Big 3": BigThreeIconY,
  "Cold Plunge": ColdPlungeIconY,
  "Group classes": GroupClasessIconY,
  Lockers: LockersIconY,
  "Nutrition Bar": NutritionBarIconY,
  "Personal training": PersonalTrainingY,
  "Red Light Therapy": RedLightIconY,
  Sauna: SaunaIconY,
  Showers: ShowersIconY,
  Spinning: SpinningIconY,
  "Steam Room": SteamRoomIconY,
  "Swimming pool": SwimmingPoolIconY,
  Tanning: TanningIconY,
  "24/7 Access": TwentyFourOpenY,
};

const AmenitiesIconList = ({ amenities }: AmenitiesIconProps) => {
  return (
    <Container>
      {amenities?.map((amenity, index) => {
        const iconSrc = iconMapping[amenity];
        return iconSrc ? (
          <BoxContainer key={uuidv4()}>
            <HiCheck style={{ color: "white" }} />
            <Tile key={uuidv4()}>{amenity}</Tile>
          </BoxContainer>
        ) : // <SubContainer>
        //   {/* <Image key={index} src={iconSrc} alt={amenity} /> */}
        // </SubContainer>
        null;
      })}
    </Container>
  );
};

export default AmenitiesIconList;
