import React, { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  FORGOT_PASS,
  FORGOT_PASS_TILE,
  LOGIN,
  LOGIN_APPLE,
  LOGIN_GOOGLE,
  LOGIN_TO,
  SEND,
} from "../../../utils/strings";
import EOMButton from "../../../components/EOMButton";
import { isTabletOrMobileSize } from "../../../utils/queries";
import theme from "../../../utils/theme";
import { AppleYellow, GoogleYellow } from "../../../assets/images";
import Line from "../../../components/Line";
import {
  loginFirebase,
  loginWithApple,
  loginWithGoogle,
  resetPassFirebase,
} from "../../../auth/operations";
import { emptyBasket } from "../../../slices/appSlice";
import { DASHBOARD_GYM_OWNERS, DASHBOARD_MEMBERS } from "../../../utils/routes";
import {
  Container,
  ForgotPassword,
  Or,
  OrContainer,
  Subcontainer,
  SocialLoginContainer,
  Title,
  CustomizedTextField,
  ErrorMessage,
  Box,
} from "./styles";

const Login = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [forgotPassEmail, setForgotPassEmail] = useState<string>("");
  const [forgotPass, setForgotPass] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { memberLoggedIn, gymOwnerLoggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  const handleLogin = async () => {
    setLoading(true);
    const result: any = await loginFirebase(username, password);
    if (result?.error?.error) {
      setError(true);
      setErrorMessage(result?.error?.message);
      setLoading(false);
    } else {
      setError(false);
      setLoading(false);
      setErrorMessage("");
      dispatch(emptyBasket());
    }
  };

  const handleForgotPass = async () => {
    await resetPassFirebase(forgotPassEmail);
    setForgotPass(!forgotPass);
    setForgotPassEmail("");
  };

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!username || !password) {
      setError(false);
      setErrorMessage("");
    }
  }, [username, password]);

  useEffect(() => {
    if (memberLoggedIn) {
      setLoading(false);
      navigate(DASHBOARD_MEMBERS);
    }
    if (gymOwnerLoggedIn) {
      setLoading(false);
      navigate(DASHBOARD_GYM_OWNERS);
    }
  }, [memberLoggedIn, gymOwnerLoggedIn]);

  return (
    <Container>
      <Title>{forgotPass ? FORGOT_PASS_TILE : LOGIN_TO}</Title>
      {forgotPass ? (
        <Subcontainer>
          <CustomizedTextField
            value={forgotPassEmail}
            onChange={(e: any) => setForgotPassEmail(e.target.value)}
            label="Enter your email address"
            placeholder="Email address"
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            type="email"
            autoComplete="off"
          />
          <EOMButton
            title={SEND}
            onPress={handleForgotPass}
            disabled={!forgotPassEmail}
            buttonStyle={{
              borderRadius: 3,
              height: 15,
              marginTop: 50,
              alignContent: "center",
            }}
            titleStyle={{
              color: "black",
              fontSize: 14,
              textAlign: "center",
            }}
          />
        </Subcontainer>
      ) : (
        <>
          <Subcontainer>
            <Box>
              <CustomizedTextField
                value={username}
                onChange={(e: any) => setUsername(e.target.value)}
                label="E-mail"
                placeholder="Email Address"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                type="email"
                autoComplete="off"
                error={error}
              />
              {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Box>
            <Box>
              <CustomizedTextField
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                label="Password"
                placeholder="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                autoFocus
                autoComplete="new-password"
                error={error}
                type={!showPassword ? "password" : "text"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ color: theme.colors.grey }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Box>
            <ForgotPassword onClick={() => setForgotPass(!forgotPass)}>
              {FORGOT_PASS}
            </ForgotPassword>
          </Subcontainer>
          <EOMButton
            title={LOGIN}
            onPress={handleLogin}
            disabled={!username || !password}
            buttonStyle={{
              borderRadius: 3,
              width: "86%",
              height: 15,
              marginTop: 30,
              alignContent: "center",
            }}
            titleStyle={{
              color: "black",
              fontSize: 14,
              textAlign: "center",
            }}
            loading={loading}
          />
          <OrContainer>
            <Line />
            <Or>or</Or>
            <Line />
          </OrContainer>
          <SocialLoginContainer>
            <EOMButton
              title={LOGIN_APPLE}
              onPress={() => loginWithApple()}
              outlined
              buttonStyle={{
                borderRadius: 3,
                width: "86%",
                height: 15,
                marginTop: 30,
                alignContent: "center",
                border: `1px solid ${theme.templateColors.lightYellow}`,
              }}
              titleStyle={{
                color: theme.colors.white,
                fontSize: isTabletOrMobile ? 12 : 14,
                textAlign: "center",
              }}
              icon={AppleYellow}
            />
            <EOMButton
              title={LOGIN_GOOGLE}
              onPress={() => loginWithGoogle()}
              outlined
              buttonStyle={{
                borderRadius: 3,
                width: "86%",
                height: 15,
                marginTop: 30,
                alignContent: "center",
                border: `1px solid ${theme.templateColors.lightYellow}`,
              }}
              titleStyle={{
                color: theme.colors.white,
                fontSize: isTabletOrMobile ? 12 : 14,
                textAlign: "center",
              }}
              icon={GoogleYellow}
            />
          </SocialLoginContainer>
        </>
      )}
    </Container>
  );
};

export default Login;
