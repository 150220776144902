import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.black};
  position: relative;
  width: 100%;
  z-index: 20;
  padding-bottom: 7%;
  font-family: "Inter";
  @media (max-width: 1224px) {
    padding-top: 10%;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.black};
  position: relative;
  margin-bottom: 50px;
  margin-top: 150px;
  z-index: 20;
  width: 65%;
  @media (max-width: 1224px) {
    margin-top: 50px;
    width: 90%;
  }
`;

export const Title = styled.span`
  text-align: left;
  font-weight: 700;
  font-size: ${fontSizes.bigTitle};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.bigTitleMobile};
  }
`;

export const Subtitle = styled.span`
  margin-top: 10px;
  font-size: ${fontSizes.subtitle};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background-color: ${colors.black};
  position: relative;
  height: 600px;
  width: 85%;
  z-index: 20;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px; /* Remove the scrollbar width */
    height: 0px; /* Remove the scrollbar height */
  }

  @media (max-width: 1224px) {
    width: 95%;
    justify-content: center;
  }
`;
