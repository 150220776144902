import styled from "styled-components";

import theme from "../../utils/theme";

const { templateColors } = theme;

export const Container = styled.div`
  top: 14px;
  right: 0px;
  position: fixed;
  display: flex;
  z-index: 601;
  @media (min-width: 1024px) and (max-width: 1224px) {
    right: 15px;
    top: 11px;
  }
  @media (max-width: 1224px) {
    top: 11px;
  }
`;

export const SubContainer = styled.div`
  @media (min-width: 1024px) and (max-width: 1224px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${templateColors.lightDark};
    padding-top: 50px;
    padding: 25px;
    top: 50px;
    right: 0px;
    width: 100px;
    position: absolute;
    border-radius: 2px;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${templateColors.lightDark};
    padding-top: 50px;
    padding: 25px;
    top: 50px;
    right: 0px;
    width: 100px;
    position: absolute;
    border-radius: 2px;
  }
`;
