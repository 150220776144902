import React from "react";

import {
  Container,
  LogoStyle,
  Title,
  LeftContainer,
  Description,
  RightContainer,
} from "./styles";
import { ArrowRight } from "../../assets/images";

interface CardProps {
  id: string;
  title: string;
  description: string;
}
const Card = ({ id, title, description }: CardProps) => {
  return (
    <Container id={id}>
      <LeftContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </LeftContainer>
      <RightContainer>
        <LogoStyle src={ArrowRight} alt="arrow" />
      </RightContainer>
    </Container>
  );
};

export default Card;
