import React, { useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";
import { useNavigate } from "react-router-dom";

import { Basket, BasketNoti, LogoLetters } from "../../../assets/images";
import { navbarTiles } from "../../../utils/arrays";
import { isTabletOrMobileSize } from "../../../utils/queries";
import { DASHBOARD, LOGIN } from "../../../utils/strings";
import {
  CHECKOUT,
  DASHBOARD_GYM_OWNERS,
  DASHBOARD_MEMBERS,
  HOME,
} from "../../../utils/routes";
import Modal from "../../../components/Modal";
import Login from "../../auth/Login";
import theme from "../../../utils/theme";
import {
  Container,
  LeftContainer,
  RightContainer,
  LogoStyle,
  NavbarTitle,
  BasketStyle,
  TitleTab,
  LoginButton,
  LogoContainer,
} from "./styles";

interface NavbarProps {
  dashboardScreen: boolean;
  checkoutScreen: boolean;
}
const Navbar = ({ dashboardScreen, checkoutScreen }: NavbarProps) => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });
  const [showModal, setShowModal] = useState<boolean>(false);

  const { gymOwnerLoggedIn, memberLoggedIn, loggedIn } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();

  const { basketProduct } = useSelector((state: RootState) => state.appData);
  const productInBasket = !!basketProduct?.businessId ?? false;

  const handleOpenModal = () => {
    setShowModal(!showModal);
  };

  const handleOpenDashboard = () => {
    if (gymOwnerLoggedIn) navigate(DASHBOARD_GYM_OWNERS);
    if (memberLoggedIn) navigate(DASHBOARD_MEMBERS);
  };

  const handleBasket = () => {
    if (productInBasket) navigate(CHECKOUT);
  };

  const userDashboard = memberLoggedIn || gymOwnerLoggedIn;
  const desktopNotLoginUser = !loggedIn && !isTabletOrMobile;

  return (
    <Container>
      <LeftContainer>
        <LogoContainer>
          <Link to={HOME} style={{ textDecoration: "none" }}>
            <LogoStyle
              src={LogoLetters}
              alt="logo"
              dashboardscreen={dashboardScreen ? "true" : "false"}
            />
          </Link>
        </LogoContainer>
        {!isTabletOrMobile &&
          !gymOwnerLoggedIn &&
          !memberLoggedIn &&
          !checkoutScreen && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {navbarTiles.map(({ id, name }) => (
                <Link key={id} to={id} style={{ textDecoration: "none" }}>
                  <TitleTab>
                    <NavbarTitle>{name}</NavbarTitle>
                  </TitleTab>
                </Link>
              ))}
            </div>
          )}
      </LeftContainer>
      <RightContainer>
        {(userDashboard || desktopNotLoginUser || checkoutScreen) && (
          <LoginButton
            onClick={loggedIn ? handleOpenDashboard : handleOpenModal}
          >
            {loggedIn ? DASHBOARD : LOGIN}
          </LoginButton>
        )}
        {!gymOwnerLoggedIn && (
          <div onClick={handleBasket}>
            <BasketStyle
              src={productInBasket && !loggedIn ? BasketNoti : Basket}
              alt="basket"
              userdashboard={userDashboard || checkoutScreen ? "true" : "false"}
              basketproduct={productInBasket && !loggedIn ? "true" : "false"}
            />
          </div>
        )}
      </RightContainer>
      <Modal
        showModal={showModal}
        onClose={handleOpenModal}
        content={<Login />}
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default Navbar;
