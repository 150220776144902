import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  z-index: 120;
  width: 100%;
  padding: 4% 0% 10% 0%;
  font-family: "Rubik";
`;

export const Title = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.title};
  color: ${colors.white};
  margin-bottom: 6%;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const TitleTwo = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.subtitle};
  color: ${colors.white};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: ${fontSizes.description};
  color: ${templateColors.secondaryGrey};
  margin-bottom: 7%;
  margin-top: 3px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const Icon = styled.img`
  height: 80px;
  width: 80px;
  margin-bottom: 5%;
  @media (max-width: 1224px) {
    height: 40px;
    width: 40px;
  }
`;
