/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Business, Product, UserData } from "../utils/types";

export interface AppSliceState {
  basketProduct: Business | null;
  userData: UserData;
  loading: boolean;
  businessProfile: any;
  selectedProduct: Product;
  allBusiness: any;
  userExist: boolean;
  firebaseUser: any;
  userMembershipStatus: any;
  membershipUpgraded: boolean;
  isNextGymSelected: boolean;
  businessDashboardData: any;
  partnerGymInfo: any;
  userCanDowngradeToBasic: boolean;
  clientSecretStripe: any;
}

const appDataInitialState: AppSliceState = {
  basketProduct: {
    businessId: "",
    name: "",
    type: "",
    description: "",
    gallery: [],
    amenities: [],
    address: { addressId: "", fullAddress: "" },
    socialLinks: [
      {
        name: "",
        socialLink: "",
      },
    ],
    products: [],
  },
  userData: {
    userId: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    currencyCode: "",
    address: "",
    role: "",
  },
  loading: false,
  businessProfile: {},
  selectedProduct: {
    businessProductId: "",
    businessId: "",
    name: "",
    preTaxCost: "",
    createdAt: "",
    price: {
      feeAmount: 0,
      preTaxCost: 0,
      taxAmount: 0,
      total: 0,
    },
    stripeProductId: "",
  },
  allBusiness: [],
  userExist: false,
  firebaseUser: {},
  userMembershipStatus: {},
  membershipUpgraded: false,
  isNextGymSelected: false,
  businessDashboardData: [],
  partnerGymInfo: {},
  userCanDowngradeToBasic: false,
  clientSecretStripe: null,
};

const appData = createSlice({
  name: "appData",
  initialState: appDataInitialState,
  reducers: {
    setUserData(state, { payload }: PayloadAction<any>) {
      state.userData = payload;
    },
    addBasketProduct(state, { payload }: PayloadAction<any>) {
      state.basketProduct = payload;
    },
    emptyBasket(state) {
      state.basketProduct = null;
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.loading = payload;
    },
    setBusinessProfile(state, { payload }: PayloadAction<any>) {
      state.businessProfile = payload;
    },
    setSelectedProduct(state, { payload }: PayloadAction<Product>) {
      state.selectedProduct = payload;
    },
    setAllBusiness(state, { payload }: PayloadAction<string>) {
      state.allBusiness = payload;
    },
    setUserExist(state, { payload }: PayloadAction<boolean>) {
      state.userExist = payload;
    },
    setFirebaseUser(state, { payload }: PayloadAction<any>) {
      state.firebaseUser = payload;
    },
    setUserMembershipStatus(state, { payload }: PayloadAction<any>) {
      state.userMembershipStatus = payload;
    },
    setMembershipUpgraded(state, { payload }: PayloadAction<boolean>) {
      state.membershipUpgraded = payload;
    },
    setIsNextGymSelected(state, { payload }: PayloadAction<boolean>) {
      state.isNextGymSelected = payload;
    },
    setBusinessDashboardData(state, { payload }: PayloadAction<any>) {
      state.businessDashboardData = payload;
    },
    setPartnerGymInfo(state, { payload }: PayloadAction<any>) {
      state.partnerGymInfo = payload;
    },
    setUserCanDowngradeToBasic(state, { payload }: PayloadAction<boolean>) {
      state.userCanDowngradeToBasic = payload;
    },
    setClientSecretStripe(state, { payload }: PayloadAction<boolean>) {
      state.clientSecretStripe = payload;
    },
  },
});

export const {
  setUserData,
  addBasketProduct,
  emptyBasket,
  setLoading,
  setBusinessProfile,
  setSelectedProduct,
  setAllBusiness,
  setUserExist,
  setFirebaseUser,
  setUserMembershipStatus,
  setMembershipUpgraded,
  setIsNextGymSelected,
  setBusinessDashboardData,
  setPartnerGymInfo,
  setUserCanDowngradeToBasic,
  setClientSecretStripe,
} = appData.actions;

export default appData.reducer;
