import React, { useState } from "react";

import { Container, Icon, SubContainer, Tile } from "./styles";

interface Amenity {
  amenityName: string;
  icon: string;
}

interface AmenitiesFormListProps {
  amenities: Amenity[];
  selectedAmenities: string[];
  onAmenityClick: (e: any, amenityName: string) => void;
}

const AmenitiesFormList = ({
  amenities,
  selectedAmenities,
  onAmenityClick,
}: AmenitiesFormListProps) => (
  <Container>
    {amenities.map((amenity) => (
      <SubContainer
        key={amenity.amenityName}
        onClick={(e) => onAmenityClick(e, amenity.amenityName)}
      >
        <Icon
          src={amenity.icon}
          alt={amenity.amenityName}
          selected={selectedAmenities.includes(amenity.amenityName)}
        />
        <Tile>{amenity.amenityName}</Tile>
      </SubContainer>
    ))}
  </Container>
);

export default AmenitiesFormList;
