import styled from "styled-components";

import theme from "../../utils/theme";

const { templateColors } = theme;

export const StyledLine = styled.div`
  /* border: 0.0px solid ${theme.templateColors.cream}; */
  border-bottom: 1px solid ${theme.templateColors.cream};
  width: 100%;
  opacity: 0.4;
`;
