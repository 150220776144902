import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

interface ContainerProps {
  marginTop?: string;
}

interface TitleTwoProps {
  secondaryTitleColor?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props.marginTop};
  background-color: ${templateColors.primaryBlack};
  height: 65px;
  @media (max-width: 1224px) {
    height: 40px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 100px;
  align-items: flex-start;
  padding-left: 5%;

  @media (max-width: 1224px) {
  }
`;

export const Icon = styled.img`
  height: 25px;
  width: 25px;
  padding-left: 5%;

  @media (max-width: 1224px) {
    height: 17px;
    width: 17px;
  }
`;

export const IconRight = styled.img`
  height: 21px;
  width: 21px;
  padding-right: 5%;
  position: absolute;
  right: 0;
  @media (max-width: 1224px) {
    height: 16px;
    width: 16px;
  }
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: ${fontSizes.titleMobile};
  color: ${colors.white};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const TitleTwo = styled.span<TitleTwoProps>`
  font-weight: 600;
  font-size: ${fontSizes.titleMobile};
  color: ${(props) => props.secondaryTitleColor};
  position: absolute;
  right: 45%;
  cursor: pointer;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const SubTitle = styled.span`
  font-weight: 400;
  font-size: ${fontSizes.description};
  color: ${templateColors.secondaryGrey};
  margin-top: 3px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const Button = styled.span`
  font-weight: 600;
  font-size: ${fontSizes.titleMobile};
  color: ${templateColors.lightYellow};
  padding-right: 5%;
  position: absolute;
  right: 0;
  cursor: pointer;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;
