import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  width: 100%;
  padding-top: 10%;
  padding-bottom: 4%;
  font-family: "Inter";
  @media (max-width: 1224px) {
    padding-top: 18%;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6%;

  @media (max-width: 1224px) {
    align-items: center;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  @media (max-width: 1224px) {
    width: 86%;
    align-self: center;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 4%;
  align-items: center;
  @media (max-width: 1224px) {
    justify-content: center;
    padding-left: 0%;
    margin-bottom: 5%;
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.bigTitle};
  font-weight: 700;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.bigTitleMobile};
  }
`;

export const Subtitle = styled.span`
  font-size: ${fontSizes.title};
  padding-top: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const Image = styled.img`
  height: 600px;
  width: 600px;
  @media (max-width: 1224px) {
    height: 350px;
    width: 350px;
  }
`;

export const GymName = styled.span`
  font-size: ${fontSizes.description};
  margin-top: 10px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1224px) {
    flex-direction: column-reverse;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0% 2% 2% 2%;
  @media (max-width: 1224px) {
    width: 80%;
    margin-top: 8%;
    justify-content: center;
    align-self: center;
  }
`;

export const AboutUsSubtitle = styled.span`
  font-size: ${fontSizes.mediumTitle};
  font-weight: 500;
  padding-bottom: 5%;

  @media (max-width: 1224px) {
    font-size: ${fontSizes.mediumTitleMobile};
    padding-top: 10%;
  }
`;

export const Description = styled.span`
  font-size: ${fontSizes.subtitle};
  line-height: 40px;

  &::before {
    content: "Mission: ";
    font-size: ${fontSizes.subtitle};
    font-weight: 700;

    @media (max-width: 1224px) {
      font-size: ${fontSizes.subtitleMobile};
    }
  }
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const DescriptionTwo = styled.span`
  font-size: ${fontSizes.subtitle};
  line-height: 40px;
  padding-top: 4%;

  &::before {
    content: "Vision: ";
    font-size: ${fontSizes.subtitle};
    font-weight: 700;

    @media (max-width: 1224px) {
      font-size: ${fontSizes.subtitleMobile};
    }
  }
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const DescriptionThree = styled.span`
  font-size: ${fontSizes.subtitle};
  line-height: 40px;
  padding-top: 4%;

  &::before {
    content: "Community: ";
    font-size: ${fontSizes.subtitle};
    font-weight: 700;

    @media (max-width: 1224px) {
      font-size: ${fontSizes.subtitleMobile};
    }
  }
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: 1224px) {
    width: 90%;
    align-self: center;
    margin-top: 5%;
  }
`;
