import styled from "styled-components";
import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 60%;
  height: 80px;
  @media (max-width: 1224px) {
    width: 90%;
  }
  /* gap: 8px; */
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Image = styled.img`
  width: 19px;
  height: 19px;
`;

export const Tile = styled.span`
  font-size: 10px;
  margin-left: 5px;
  margin-top: 2px;
  color: ${templateColors.primaryGrey};
  @media (max-width: 1224px) {
    margin-right: 3px;
  }
`;
