import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${templateColors.primaryBlack};
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Rubik";
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  position: relative;
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  @media (min-height: 1024px) and (max-height: 1369px) {
    height: 40%;
  }

  @media (min-width: 1025px) {
    margin-bottom: 70px;
    height: 68%;
  }
`;

export const BottomContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  background-color: ${templateColors.primaryBlack};
`;
