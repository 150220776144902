import React, { useEffect, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import EOMButton from "../../../components/EOMButton";
import { PARTNER_SIGNUP, SIGNUP } from "../../../utils/strings";
import { Logo, LogoLetters } from "../../../assets/images";
import { signUpFirebase } from "../../../auth/operations";
import theme from "../../../utils/theme";
import { DASHBOARD_GYM_OWNERS } from "../../../utils/routes";
import { emptyBasket } from "../../../slices/appSlice";
import { useApi } from "../../../hooks";
import { invitePartner } from "../../../api/apiEndpoints";
import {
  Container,
  CustomizedTextField,
  CustomizedTextFieldEmail,
  LogoStyle,
  Subcontainer,
  Title,
} from "./styles";

const SignUpGymOwner = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<boolean>(false);

  let { tokenValue } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    request: invitePartnerReq,
    data: invitePartnerData,
    error: invitePartnerError,
  } = useApi<any>({
    apiFunc: invitePartner,
  });

  const handleSignup = async () => {
    setLoading(true);
    const result = await signUpFirebase(username, password, true);
    if (!!result) {
      setLoading(false);
      dispatch(emptyBasket());
      navigate(DASHBOARD_GYM_OWNERS);
    }
  };

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!token) {
      if (!!tokenValue) {
        setToken(true);
        invitePartnerReq(tokenValue);
      }
    }
  }, [tokenValue]);

  useEffect(() => {
    setUsername(invitePartnerData?.email);
  }, [invitePartnerData]);

  useEffect(() => {
    if (invitePartnerError) alert("Something went wrong");
  }, [invitePartnerError]);

  return (
    <Container>
      <LogoStyle src={LogoLetters} />
      <Title>{PARTNER_SIGNUP}</Title>
      <Subcontainer>
        <CustomizedTextFieldEmail
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={username}
          disabled
          autoFocus
          style={{ WebkitTextFillColor: `${theme.colors.grey}` }}
        />
        <CustomizedTextField
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
          label="Your New Password"
          placeholder="Enter Password"
          InputLabelProps={{
            shrink: true,
          }}
          autoFocus
          type={!showPassword ? "password" : "text"}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  style={{ color: theme.colors.grey }}
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <EOMButton
          title={SIGNUP}
          onPress={handleSignup}
          disabled={!username || !password}
          buttonStyle={{
            borderRadius: 3,
            height: 15,
            marginTop: 60,
            alignContent: "center",
          }}
          loading={loading}
          titleStyle={{
            color: "black",
            fontSize: 14,
            textAlign: "center",
          }}
        />
      </Subcontainer>
    </Container>
  );
};

export default SignUpGymOwner;
