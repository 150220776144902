import { combineReducers } from "@reduxjs/toolkit";

import appDataReducer from "../slices/appSlice";
import authReducer from "../slices/authSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  appData: appDataReducer,
});

declare global {
  type RootState = ReturnType<typeof rootReducer>;
}

export default rootReducer;
