import styled from "styled-components";
import TextField from "@mui/material/TextField";

import { styled as MUIstyled } from "@mui/material/styles";
import theme from "../../../utils/theme";

const { colors, fontSizes, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  z-index: 120;
  padding-bottom: 20%;
  @media (max-width: 1224px) {
    padding-bottom: 10%;
    padding-left: 4%;
    padding-right: 4%;
  }
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-top: 5%;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
`;

export const Title = styled.span`
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  color: ${colors.white};
  @media (max-width: 1224px) {
    font-size: 15px;
  }
`;

export const ForgotPassword = styled.div`
  cursor: pointer;
  color: ${templateColors.lightYellow};
  font-weight: 600;
  margin-top: 18px;
  margin-bottom: 6px;
  font-size: 12px;
  align-self: flex-start;
  @media (max-width: 1224px) {
    font-size: 10px;
  }
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const OrContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 90%;
`;

export const Or = styled.span`
  font-size: ${fontSizes.description};
  margin-left: 10px;
  margin-right: 10px;
  color: ${templateColors.cream};
`;

export const ErrorMessage = styled.span`
  font-size: ${fontSizes.description};
  color: ${colors.red};
  align-self: flex-start;
  padding-top: 3px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const CustomizedTextField = MUIstyled(TextField)`
  background-color: ${templateColors.lightDark};
  width: 100%;
  margin-top: 30px;

  & .MuiInputLabel-formControl {
    color: ${colors.white} !important;
  }

  & .MuiInput { 
    &::input {
      &::placeholder {
        font-size: ${fontSizes.description};
        color: ${colors.grey} !important;
      }
    }
  }
  & .MuiOutlinedInput-root {
    & fieldset>legend {
      padding-right: 12px;
    }
    &.Mui-focused fieldset {
      border: 1px solid ${templateColors.lightYellow};
    }
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
  }

  & .MuiInputBase-root {
    color: ${colors.grey} !important;
  }
 
  & .MuiOutlinedInput-root MuiInputBase {
    color: ${colors.white} !important;
  }

  & .MuiFormLabel-root-bhkJJT {
    color: ${colors.white} !important;
    font-size: ${fontSizes.subtitle};
    padding-right: 5px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
    :hover {
      border: 1px solid ${colors.white} !important;
    }

    & .Mui-focused .MuiInputAdornment-root {
      border: 1px solid ${templateColors.lightYellow};
    }
  }
 
`;
