import React, { useState, useRef, useEffect } from "react";

import DashboardListing from "../../../components/DashboardListing";
import NavbarContainer from "../../../components/NavbarContainer";
import DashboardHeader from "../../../components/DashboardHeader";
import { DefaultAvatarGymOwner } from "../../../assets/images";
import FooterSection from "../FooterSection";
import EditGymCard from "../../../components/EditGymCard";
import { Container } from "./styles";
import { useApi } from "../../../hooks";
import { getBusinessDashboardDataCall } from "../../../api/apiOperations";
import { useSelector } from "react-redux";

const DashboardGymOwners = () => {
  const [showGymCardEdition, setShowGymCardEdition] = useState<boolean>(false);

  const myRef = useRef<HTMLElement | null>(null);

  const handleShowGymCard = () => {
    setShowGymCardEdition(!showGymCardEdition);
    !showGymCardEdition && myRef.current?.scrollIntoView();
    showGymCardEdition && window.scrollTo(0, 0);
  };

  const { partnerGymInfo } = useSelector(
    (state: RootState) => state.appData || {}
  );

  const { request: getBusinessDataReq } = useApi<any>({
    apiFunc: getBusinessDashboardDataCall,
  });

  useEffect(() => {
    getBusinessDataReq(partnerGymInfo?.businessId);
  }, [partnerGymInfo]);

  return (
    <Container>
      <NavbarContainer dashboardScreen />
      <DashboardHeader avatar={DefaultAvatarGymOwner} />
      <DashboardListing handleOpenGymCardForm={handleShowGymCard} />
      <section ref={myRef} id="my-section" />
      {showGymCardEdition && <EditGymCard close={handleShowGymCard} />}{" "}
      <section />
      <FooterSection />
    </Container>
  );
};

export default DashboardGymOwners;
