import React from "react";

import { Container, Title, Description, Icon } from "./styles";
import { FailureIcon, SuccessIcon } from "../../assets/images";
import {
  MEMBERSHIP_SUBSCRIPTION,
  TAX,
  TOTAL,
  TRANSACTION_FEE,
  TRY_AGAIN,
} from "../../utils/strings";
import EOMButton from "../EOMButton";
import { isTabletOrMobileSize } from "../../utils/queries";
import { useMediaQuery } from "react-responsive";
import theme from "../../utils/theme";
import {
  Tile,
  TilePurchaseBox,
  ValueTile,
  BriefSummary,
} from "../SummaryCard/styles";
import Line from "../Line";

interface StatusScreenProps {
  title?: string;
  description?: string;
  failure: boolean;
  onClose?: () => void;
  successButtonTile?: string;
  showIcon?: boolean;
  loading?: boolean;
  onButtonClick?: () => void;
  useButtonEvent?: boolean;
  showButton?: boolean;
  showDescription?: boolean;
  info?: any;
  showSummary?: boolean;
}

const StatusScreen = ({
  title,
  description,
  failure,
  onClose,
  successButtonTile = "OK",
  showIcon = true,
  loading,
  onButtonClick,
  useButtonEvent = false,
  showButton = true,
  showDescription,
  showSummary = false,
  info,
}: StatusScreenProps) => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  const { total, upgradeMembershipPrice, taxAmount, feeAmount, preTaxCost } =
    info || {};

  return (
    <Container>
      {showIcon && (
        <>{failure ? <Icon src={FailureIcon} /> : <Icon src={SuccessIcon} />}</>
      )}
      <Title>{title}</Title>
      <Description>{showDescription && description}</Description>
      {showSummary && (
        <BriefSummary>
          <TilePurchaseBox>
            <Tile>{MEMBERSHIP_SUBSCRIPTION}</Tile>
            <ValueTile>$ {preTaxCost}</ValueTile>
          </TilePurchaseBox>
          <TilePurchaseBox>
            <Tile>{TAX}</Tile>
            <ValueTile>$ {taxAmount}</ValueTile>
          </TilePurchaseBox>
          <TilePurchaseBox>
            <Tile>{TRANSACTION_FEE}</Tile>
            <ValueTile>$ {feeAmount}</ValueTile>
          </TilePurchaseBox>
          <Line />
          <TilePurchaseBox>
            <Tile>{TOTAL}</Tile>
            <ValueTile>$ {total}</ValueTile>
          </TilePurchaseBox>
        </BriefSummary>
      )}
      {showButton && (
        <EOMButton
          title={failure ? TRY_AGAIN : successButtonTile}
          onPress={useButtonEvent ? onButtonClick : onClose}
          buttonStyle={{
            borderRadius: 3,
            width: isTabletOrMobile ? "40%" : "45%",
            height: 15,
            marginTop: 30,
            alignContent: "center",
            border: `1px solid ${theme.templateColors.lightYellow}`,
          }}
          loading={loading}
          titleStyle={{
            color: theme.colors.black,
            fontSize: isTabletOrMobile ? 11 : 14,
            textAlign: "center",
            fontWeight: 450,
          }}
        />
      )}
    </Container>
  );
};

export default StatusScreen;
