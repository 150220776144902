import { useState } from "react";

export const useApi = <T>({
  apiFunc,
  initialLoading = false,
}: {
  initialLoading?: boolean;
  apiFunc: any;
}) => {
  const [data, setData] = useState<T>();
  // make the error an actual code, or null if not 200
  const [error, setError] = useState<null | boolean | number>(null);
  const [loading, setLoading] = useState(initialLoading);
  const [status, setStatus] = useState<null | number>(null);

  const request = async (...args: any) => {
    setLoading(true);
    try {
      let response = await apiFunc(...args);
      setLoading(false);

      if (response.status !== 200) {
        setError(true);
        return;
      }
      setError(null);
      setData(response.data);
      setStatus(response.status);
    } catch (e) {
      setLoading(false);

      // no code, but not null. Something local probably happened
      setError(1);
    }
  };

  const clear = () => {
    setError(null);
    setData(undefined);
    setLoading(false);
  };

  return { data, error, loading, status, request, setError, clear };
};
