const colors = {
  blue: "#006BFF",
  grey: "#999",
  white: "#fff",
  black: "#000",
  lightGrey: "#ccc",
  darkGrey: "#666",
  red: "#FF0000",
  green: "#5cb85c",
};

const templateColors = {
  lightYellow: "#DFEC49",
  primaryBlack: "#000", //este es el color negro de fondo
  lightGrey: "#F6F5F1",
  lightDark: "#232532", // este es el color del navbar y la card de sumarry card
  secondaryBlack: "#373737",
  backgroundWhite: "#F6F6F6",
  buttonDisabled: "#7E8433",
  primaryGrey: "#D9D9D9",
  secondaryGrey: "#4F5261",
  cream: "#E6E6E6",
  lightViolet: "#bdafe5",
  grey: "#525252",
};

const fontSizes = {
  bigTitle: "45px",
  bigTitleMobile: "30px",
  mediumTitle: "30px",
  mediumTitleMobile: "23px",
  title: "22px",
  titleMobile: "14px",
  subtitle: "18px",
  subtitleMobile: "12px",
  description: "13px",
  descriptionMobile: "11px",
  xs: "11px",
  xsMobile: "9px",
};

const fonts = {
  DomaineDisplayRegular: "DomaineDisplay-Regular",
  DomaineTextMedium: "DomaineText-Medium",
  FuturaBold: "Futura-Bold",
  Futura: "Futura",
  GintoBlack: "Ginto-Black",
  GintoBold: "Ginto-Bold",
  GintoRegular: "Ginto-Regular",
  GintoLight: "Ginto-Light",
  GintoMedium: "Ginto-Medium",
  GothamBlack: "Gotham-Black",
  GothamBold: "Gotham-Bold",
  GothamBookItalic: "Gotham-BookItalic",
  GothamItalic: "Gotham-Italic",
  GothamMedium: "Gotham-Medium",
  GothamThin: "Gotham-Thin",
  GothamThinItalic: "Gotham-ThinItalic",
  GothamUltraItalic: "Gotham-UltraItalic",
  GothamXLight: "Gotham-XLight",
  GothamXLightItalic: "Gotham-XLightItalic",
  PoppinsBold: "Poppins-Bold",
  PoppinsRegular: "Poppins-Regular",
  DomineRegular: "Domine-Regular",
  DomineMedium: "Domine-Medium",
  DomineBold: "Domine-Bold",
  DomineSemiBold: "Domine-SemiBold",
};

const theme = {
  colors,
  templateColors,
  fonts,
  fontSizes,
};

export default theme;
