import React, { useEffect } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import DashboardHeader from "../../../components/DashboardHeader";
import NavbarContainer from "../../../components/NavbarContainer";
import FooterSection from "../FooterSection";
import {
  SELECT_YOUR_NEXT_GYM,
  JACKED_RABBIT_MODE_DESCRIPTION,
  MEMBERSHIP_UPGRADED,
  ACTIVE_MEMBERSHIPS,
  NO_ACTIVE_MEMBERSHIP,
} from "../../../utils/strings";
import InfoCard from "../../../components/InfoCard";
import { DefaultAvatarMember } from "../../../assets/images";
import GymCard from "../../../components/GymCard";
import { getUserMembershipStatusCall } from "../../../api/apiOperations";
import { useApi } from "../../../hooks";
import { filterByName, formatDate } from "../../../utils/utils";
import {
  Container,
  SubContainer,
  Tile,
  GymHopContainer,
  GymHopeTitle,
  GymHopeDescription,
  GymHop,
  Box,
} from "./styles";

const DashboardMembers = () => {
  const {
    allBusiness,
    userMembershipStatus,
    businessProfile,
    membershipUpgraded,
    isNextGymSelected,
    userCanDowngradeToBasic,
  } = useSelector((state: RootState) => state.appData);
  const dispatch = useDispatch();

  const { memberships } = userMembershipStatus || {};

  const { name } = memberships?.active?.business || {};
  const { expiresAt } = memberships?.active || {};

  const { price } = filterByName(businessProfile?.products, "premium") || {};
  const basicProduct = filterByName(businessProfile?.products, "basic") || {};

  const { preTaxCost } = price || 0;
  const basicPrice = basicProduct?.price?.preTaxCost || 0;

  const upgradePrice = preTaxCost - basicPrice;

  const { request: getUserMembershipStatusCallReq } = useApi<any>({
    apiFunc: getUserMembershipStatusCall,
  });

  useEffect(() => {
    getUserMembershipStatusCallReq();
  }, []);

  return (
    <Container>
      <NavbarContainer dashboardScreen />
      <DashboardHeader avatar={DefaultAvatarMember} />
      {!!memberships?.settings?.scheduledCancelAt ? (
        <SubContainer>
          <Tile>{NO_ACTIVE_MEMBERSHIP}</Tile>
        </SubContainer>
      ) : (
        <>
          <SubContainer>
            <Tile>{ACTIVE_MEMBERSHIPS}</Tile>
            <InfoCard
              tileOne={name}
              subtile={moment().format("MMM Do YY")}
              title={""}
              description={"Membership expiration: "}
              descriptionTwo={memberships?.active?.product?.name}
              descriptionThree={formatDate(expiresAt)}
              bottomTile={
                membershipUpgraded
                  ? MEMBERSHIP_UPGRADED
                  : `Upgrade membership for $ ${upgradePrice.toFixed(2)} USD`
              }
              upgradeMembershipPrice={upgradePrice}
            />
          </SubContainer>
          {(!isNextGymSelected || userCanDowngradeToBasic) && (
            <GymHopContainer>
              <GymHopeTitle>{SELECT_YOUR_NEXT_GYM}</GymHopeTitle>
              <Box>
                <GymHopeDescription>
                  {JACKED_RABBIT_MODE_DESCRIPTION}
                </GymHopeDescription>
              </Box>
              <GymHop>
                {allBusiness?.map((gymCard: any) => (
                  <GymCard key={gymCard.id} gymCard={gymCard} />
                ))}
              </GymHop>
            </GymHopContainer>
          )}
        </>
      )}
      <FooterSection />
    </Container>
  );
};

export default DashboardMembers;
