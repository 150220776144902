import axios from "axios";
import { BussinesProfile, GalleryUpdate, UpdateProfile } from "../utils/types";
import api from "./client";

// ------- User  -------

export const getStripeConfig = () => api.get("/v1/config");

export const getUserMembershipStatus = () => api.get("v1/memberships/me");

export const getUser = (idToken: string) =>
  api.get("/v1/users/me", { headers: { Authorization: `Bearer ${idToken}` } });

export const refreshUser = () => api.get("/v1/users/me");

export const updateUserProfile = (data?: UpdateProfile, idToken?: string) =>
  api.put("/v1/users/me", data, {
    headers: { Authorization: `Bearer ${idToken}` },
  });

// ------- Payments  -------

export const cancelCurrentMembership = () => api.post("/v1/memberships/cancel");

export const subscribeToProduct = (businessProductId: string) =>
  api.post("/v1/memberships/products/basic", {
    businessProductId: businessProductId,
  });

export const subscribeToNextGym = (businessProductId: string) =>
  api.post("/v1/memberships/pick", {
    businessProductId: businessProductId,
  });

export const upgradeToPremium = (businessProductId: string) =>
  api.post("/v1/memberships/upgrade", {
    businessProductId: businessProductId,
  });

// ------- Bussines (Gym Owners)  -------

export const invitePartner = (tokenData: string) => {
  const params = { token: tokenData };
  return api.get(`/v1/partners/invite`, { params });
};

export const getAllBusiness = () => api.get("/v1/business");

export const getPartnerGymInfo = () => api.get("/v1/business/my-gym");

export const getBusinessDashboardData = (buid: string) =>
  api.get(`/v1/memberships/business/${buid}`);

export const createBusinessProfile = (data: any) =>
  api.post("/v1/business", data);

export const getBusinessProfile = (id: string) => api.get(`/v1/business/${id}`);

export const getBusinessGallery = () =>
  api.get("/v1/business/gallery/signed-url");

export const updateBusinessGallery = (data: any) =>
  api.put("/v1/business/gallery", data);

// ---- Upload Image --> Step 1 ---------

export const uploadImage = (file: any) => {
  const result = api.post("/v1/business/gallery/upload", file, {
    headers: {
      ContentType: "multipart/form-data",
    },
  });
  return result;
};

// ---- Upload Image --> Step 2 ---------
export const updateGallery = (data: GalleryUpdate[]) =>
  api.put("/v1/business/gallery", data);
