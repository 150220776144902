import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors } = theme;

interface BasketStyleProps {
  basketproduct: string;
  userdashboard: string;
}
interface LogoProps {
  dashboardscreen: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${templateColors.lightDark};
  z-index: 600;
  position: fixed;
  width: 100%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 5px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 5px 8px;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 8%;
`;

export const LogoStyle = styled.img<LogoProps>`
  width: 200px;
  height: 70px;
  margin-right: 7px;
  margin-bottom: 5%;
  object-fit: cover;
  margin-left: ${(props) =>
    props.dashboardscreen === "true" ? "58px" : "75px"};
  cursor: pointer;
  @media (max-width: 1224px) {
    width: 150px;
    height: 50px;
    margin-left: 8px;
  }
`;

export const NavbarTitle = styled.span`
  font-size: 17px;
  color: ${colors.white};
  font-family: "Rubik";
  @media (max-width: 1224px) {
    font-size: 12px;
  }
`;

export const BasketStyle = styled.img<BasketStyleProps>`
  width: ${(props) => (props.basketproduct === "true" ? "35px" : "25px")};
  height: ${(props) => (props.basketproduct === "true" ? "35px" : "25px")};
  margin-left: ${(props) => (props.basketproduct === "true" ? "5px" : "15px")};
  margin-right: 25px;
  cursor: pointer;
  z-index: 560;
  @media (max-width: 1224px) {
    margin-right: ${(props) =>
      props.userdashboard === "true" ? "15px" : "60px"};
    margin-top: 5px;
    width: ${(props) => (props.basketproduct === "true" ? "25px" : "18px")};
    height: ${(props) => (props.basketproduct === "true" ? "25px" : "18px")};
  }
`;

export const TitleTab = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 25px;
  margin-left: 25px;
  height: 15px;
  padding: 10px;
  width: max-content;
  cursor: pointer;
  &:hover {
    background-color: ${templateColors.lightYellow};
  }
  @media (max-width: 1224px) {
    margin-top: 8px;
    width: 100px;
  }
`;

export const LoginButton = styled.div`
  padding: 10px 20px;
  background-color: #232532;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px;
  font-size: 17px;
  color: ${colors.white};
  font-family: "Rubik";
  &:hover {
    background-color: rgb(223, 236, 73);
    color: black;
  }
  @media (max-width: 1224px) {
    font-size: 12px;
  }
`;
