import React from "react";

import { HashLink as Link } from "react-router-hash-link";

import {
  Container,
  LeftContainer,
  TitleBox,
  Logo,
  SocialMediaBox,
  SocialIcon,
  TileRight,
  PageSectionsContainer,
  RightContainer,
  LogoContainer,
  Tile,
  EOMBox,
  EOMLogo,
  TileEOM,
  Ahref,
  ColumnTitle,
  ColumnTile,
  Column,
  ColumnsLeft,
  ColumnsRight,
} from "./styles";
import {
  DEVELOP_BY,
  EOM,
  RIGHT_RESERVED,
  JACKEDRABBIT_FOOTER,
  EXPLORE,
  BECOME_A_JACKEDRABBIT,
  SELECT_YOUR_GYM,
  FIND_YOUR_NEW_GYM,
  PARTNER,
  OUR_PARTNERS,
  BECOME_A_PARTNER,
  ABOUT_US,
  MISSION_AND_VISION,
  CONTACT,
  EMAIL,
  PHONE,
} from "../../../utils/strings";
import {
  JrFooterIcon,
  EOMlogo,
  FacebookBlack,
  InstagramBlack,
  LinkedInBlack,
  YoutubeBlack,
  LogoLettersBlack,
} from "../../../assets/images";
import { isTabletOrMobileSize } from "../../../utils/queries";
import { useMediaQuery } from "react-responsive";

const FooterSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  return (
    <Container>
      <LeftContainer>
        <TitleBox>
          <Logo src={LogoLettersBlack} alt="logo" />
        </TitleBox>
        <SocialMediaBox>
          <Ahref
            href={`https://www.facebook.com/jackedrabbitgyms`}
            target="_blank"
          >
            <SocialIcon src={FacebookBlack} />
          </Ahref>
          <Ahref
            href={`https://www.instagram.com/jacked.rabbit/`}
            target="_blank"
          >
            <SocialIcon src={InstagramBlack} />
          </Ahref>
          <Ahref
            href={`https://www.linkedin.com/company/jackedrabbit/`}
            target="_blank"
          >
            <SocialIcon src={LinkedInBlack} />
          </Ahref>
        </SocialMediaBox>
        {!isTabletOrMobile && <TileRight>{RIGHT_RESERVED}</TileRight>}
      </LeftContainer>
      <RightContainer>
        <PageSectionsContainer>
          <ColumnsLeft>
            <Column>
              <ColumnTitle> {EXPLORE}</ColumnTitle>
              <Link
                to={"#gym-hop"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {BECOME_A_JACKEDRABBIT} </ColumnTile>
              </Link>
              <Link
                to={"#gym-hop"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {SELECT_YOUR_GYM} </ColumnTile>
              </Link>
              <Link
                to={"#gym-hop"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {FIND_YOUR_NEW_GYM} </ColumnTile>
              </Link>
            </Column>
            <Column>
              <ColumnTitle> {PARTNER}</ColumnTitle>
              <Link
                to={"#become-a-partner"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {OUR_PARTNERS} </ColumnTile>
              </Link>
              <Link
                to={"#become-a-partner"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {BECOME_A_PARTNER} </ColumnTile>
              </Link>
            </Column>
          </ColumnsLeft>
          <ColumnsRight>
            <Column>
              <ColumnTitle> {ABOUT_US}</ColumnTitle>
              <Link
                to={"#about"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {MISSION_AND_VISION} </ColumnTile>
              </Link>
            </Column>
            <Column>
              <ColumnTitle> {CONTACT}</ColumnTitle>
              <Link
                to={"#contact"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {EMAIL} </ColumnTile>
              </Link>
              <Link
                to={"#contact"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingBottom: isTabletOrMobile ? 3 : 20,
                }}
              >
                <ColumnTile> {PHONE} </ColumnTile>
              </Link>
            </Column>
          </ColumnsRight>
        </PageSectionsContainer>
        <LogoContainer>
          <Ahref href={`https://www.eom-software.com/`} target="_blank">
            <Tile>{DEVELOP_BY}</Tile>
            <EOMBox>
              <EOMLogo src={EOMlogo} />
              <TileEOM>{EOM}</TileEOM>
            </EOMBox>
          </Ahref>
          {isTabletOrMobile && <TileRight>{RIGHT_RESERVED}</TileRight>}
        </LogoContainer>
      </RightContainer>
    </Container>
  );
};

export default FooterSection;
