import React, { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  FORGOT_PASS,
  FORGOT_PASS_TILE,
  LOGIN,
  LOGIN_APPLE,
  LOGIN_GOOGLE,
  LOGIN_TO,
  SEND,
} from "../../../utils/strings";
import EOMButton from "../../../components/EOMButton";
import { isTabletOrMobileSize } from "../../../utils/queries";
import theme from "../../../utils/theme";
import { AppleYellow, GoogleYellow } from "../../../assets/images";
import Line from "../../../components/Line";
import {
  auth,
  loginFirebase,
  loginWithGoogle,
  resetPassFirebase,
} from "../../../auth/operations";
import { emptyBasket } from "../../../slices/appSlice";
import { DASHBOARD_GYM_OWNERS, DASHBOARD_MEMBERS } from "../../../utils/routes";
import {
  Container,
  ForgotPassword,
  Or,
  OrContainer,
  Subcontainer,
  SocialLoginContainer,
  Title,
  CustomizedTextField,
  ErrorMessage,
  Box,
} from "./styles";

const LoginTesting = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [token, setToken] = useState<string>("fasfsas");

  const dispatch = useDispatch();

  const { idToken } = useSelector((state: RootState) => state.auth);

  const handleLogin = async () => {
    setLoading(true);
    const result: any = await loginFirebase(username, password);
    if (result?.error?.error) {
      setError(true);
      setErrorMessage(result?.error?.message);
      setLoading(false);
    } else {
      setError(false);
      setLoading(false);
      setErrorMessage("");
      dispatch(emptyBasket());
    }
  };

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!username || !password) {
      setError(false);
      setErrorMessage("");
    }
  }, [username, password]);

  useEffect(() => {
    if (!!idToken) {
      setToken(idToken);
    }
  }, [idToken]);

  return (
    <Container>
      <Title>Devs Login</Title>
      <Subcontainer>
        <Box>
          <CustomizedTextField
            value={username}
            onChange={(e: any) => setUsername(e.target.value)}
            label="E-mail"
            placeholder="Email Address"
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            type="email"
            autoComplete="off"
            error={error}
          />
          {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Box>
        <Box>
          <CustomizedTextField
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            label="Password"
            placeholder="Password"
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            autoComplete="off"
            error={error}
            type={!showPassword ? "password" : "text"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ color: theme.colors.grey }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Box>
      </Subcontainer>
      <div
        style={{
          width: "150%",
          height: "auto",
          display: "flex",
          justifyContent: "center",
          alignSelf: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <h4
          style={{
            color: "white",
            width: "60%",
            alignItems: "center",
          }}
        >
          Token: {token}
        </h4>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          width: "40%",
        }}
      >
        <button
          onClick={() => {
            navigator.clipboard.writeText(token);
          }}
          style={{ cursor: "pointer" }}
        >
          Copy
        </button>
        <button
          onClick={() => {
            setToken("");
          }}
          style={{ cursor: "pointer" }}
        >
          Delete token
        </button>
      </div>
      <EOMButton
        title={LOGIN}
        onPress={handleLogin}
        disabled={!username || !password}
        buttonStyle={{
          borderRadius: 3,
          width: "39%",
          height: 15,
          marginTop: 30,
          alignContent: "center",
        }}
        titleStyle={{
          color: "black",
          fontSize: 14,
          textAlign: "center",
        }}
        loading={loading}
      />
    </Container>
  );
};

export default LoginTesting;
