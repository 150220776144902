import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, fontSizes, templateColors } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${templateColors.primaryBlack};
  z-index: 20;
  width: 100%;
  font-family: "Inter";
  margin-top: 4%;
  z-index: 550;

  @media (max-width: 1224px) {
    margin-top: 4%;
    align-items: flex-start;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${templateColors.primaryBlack};
  position: relative;
  margin-bottom: 50px;
  z-index: 20;
  width: 80%;
  @media (max-width: 1224px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.span`
  text-align: left;
  font-weight: 700;
  font-size: ${fontSizes.bigTitle};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.bigTitleMobile};
    margin-left: 6%;
  }
`;

export const Subtitle = styled.span`
  margin-top: 10px;
  font-size: ${fontSizes.subtitle};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  background-color: ${colors.black};
  position: relative;
  width: 80%;
  z-index: 20;
  gap: 30px;
  @media (max-width: 1224px) {
    width: 95%;
    gap: 14px;
  }
`;
