import axios from "axios";

import { store } from "../redux/store";
import { authTime, isTokenExpired } from "../utils/utils";
import { getIdTokenFirebase, logoutFirebase } from "../auth/operations";
import appData from "../slices/appSlice";

export const CONTENT_TYPE = {
  Accept: "*/*",
  // Accept: "application/json",
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const apiConfigURL = axios.create({
  baseURL: process.env.REACT_APP_CONFIG_URL,
  headers: CONTENT_TYPE,
});

// Separate api object that has no interceptors
export const refreshApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: CONTENT_TYPE,
});

api.interceptors.request.use(
  async (config: any) => {
    const { idToken, loggedIn } = store.getState().auth;
    if (idToken) {
      if (isTokenExpired(idToken)) {
        //If the token has expired or is about to expire, refresh it
        await getIdTokenFirebase();
      } else {
        // Updates Authorization header with refreshed token
        config.headers["Authorization"] = `Bearer ${idToken}`;
        return config;
      }
      //If the token has expired or is about to expire, refresh it
      if (loggedIn && isTokenExpired(idToken)) {
        await getIdTokenFirebase();
      }

      // If the user has been logged in for 24 hours, log them out
      if (loggedIn && Date.now() / 1000 >= authTime(idToken) + 86400) {
        logoutFirebase();
      }
    }
    return config;
  },
  async (error) => {
    if (error.response.status == 401) {
      await getIdTokenFirebase();
    }
  }
);

export default api;
