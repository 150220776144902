import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import HeroSection from "../HeroSection";
import FooterSection from "../FooterSection";
import HowItWorksSection from "../HowItWorksSection";
import GymHopSection from "../GymHopSection";
import BecomeAPartnerSection from "../BecomeAPartnerSection";
import AboutUsSection from "../AboutUsSection";
import ContactUsSection from "../ContactUsSection";
import NavbarContainer from "../../../components/NavbarContainer";
import { useApi } from "../../../hooks";
import { getAllBusinessCall } from "../../../api/apiOperations";
import {
  Container,
  TopContainer,
  BottomContainer,
  Subcontainer,
} from "./styles";

const Home = () => {
  const { pathname } = useLocation();

  const { request: getAllBusinessCallReq } = useApi<any>({
    apiFunc: getAllBusinessCall,
  });

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getAllBusinessCallReq();
  }, []);

  return (
    <Container>
      <Subcontainer>
        <NavbarContainer dashboardScreen={false} />
      </Subcontainer>
      <TopContainer>
        <HeroSection />
      </TopContainer>
      <BottomContainer>
        <HowItWorksSection />
        <GymHopSection />
        <BecomeAPartnerSection />
        <AboutUsSection />
        <ContactUsSection />
        <FooterSection />
      </BottomContainer>
    </Container>
  );
};

export default Home;
