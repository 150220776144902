import { app } from "./firebase";
import { store } from "../redux/store";
import axios from "axios";

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  getIdToken,
  getIdTokenResult,
  signInWithPopup,
  GoogleAuthProvider,
  deleteUser,
  OAuthProvider,
} from "firebase/auth";

import {
  setGymOwnerLoggedIn,
  setIdToken,
  setLoggedIn,
  setMemberLoggedIn,
} from "../slices/authSlice";
import { getUser, updateUserProfile } from "../api/apiEndpoints";
import {
  emptyBasket,
  setUserData,
  setUserExist,
  setFirebaseUser,
  setMembershipUpgraded,
  setBusinessProfile,
  setBusinessDashboardData,
  setPartnerGymInfo,
  setIsNextGymSelected,
  setUserCanDowngradeToBasic,
} from "../slices/appSlice";
import { convertToSerializable } from "../utils/utils";
import { UpdateProfile } from "../utils/types";
import {
  getPartnerGymInfoCall,
  getUserMembershipStatusCall,
} from "../api/apiOperations";

const dispatch = store.dispatch;

const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export const auth = getAuth(app);

export const signUpFirebase = async (
  email: string,
  password: string,
  gymOwnerType?: boolean,
  checkout?: boolean,
  updateUser?: UpdateProfile
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential?.user;

    dispatch(setFirebaseUser(convertToSerializable(user)));

    await signInWithEmailAndPassword(auth, email, password);

    const idToken = await getIdToken(user, true);
    await updateUserProfile(updateUser, idToken);
    const userData = await getUser(idToken);

    dispatch(setIdToken(idToken));
    dispatch(setUserExist(true));

    await getUserMembershipStatusCall();
    // -------- we dont set login status here, we do it affter successfull payment.
    if (!checkout) {
      dispatch(setLoggedIn(true));
      dispatch(setUserData(userData?.data?.user));

      // -------- We validate user type twice just to make sure that is the type that we want
      if (gymOwnerType || userData?.data?.user?.role === "partner")
        dispatch(setGymOwnerLoggedIn(true));
      // Grab all partner gym information
      await getPartnerGymInfoCall();
    }
    return "success";
  } catch (error: any) {
    return error.code;
  }
};

export const loginFirebase = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    const user = userCredential?.user;

    dispatch(setFirebaseUser(convertToSerializable(user)));

    const idToken = await getIdToken(user, true);
    const userData = await getUser(idToken);

    dispatch(setIdToken(idToken));
    dispatch(setUserData(userData?.data?.user));
    dispatch(setLoggedIn(true));
    dispatch(setMemberLoggedIn(userData?.data?.user?.role === "member"));
    dispatch(setGymOwnerLoggedIn(userData?.data?.user?.role === "partner"));
    dispatch(emptyBasket());
    await getUserMembershipStatusCall();

    if (userData?.data?.user?.role === "partner") {
      // Grab all partner gym information
      await getPartnerGymInfoCall();
    }

    return user;
  } catch (error: any) {
    if (
      error.code === "auth/invalid-email" ||
      error.code === "auth/invalid-credential"
    ) {
      const error = {
        error: true,
        message: "Invalid credentials",
      };
      return { error };
    }
  }
};

export const userObserver = (user: any) =>
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

export const loginWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    // The signed-in user info.
    const user = result.user;
    dispatch(setFirebaseUser(convertToSerializable(user)));

    const idToken = await getIdToken(user, true);
    dispatch(setIdToken(idToken));
    const userData = await getUser(idToken);

    if (token) dispatch(setLoggedIn(true));
    // IdP data available using getAdditionalUserInfo(result)
    // ...

    dispatch(setUserData(userData?.data?.user));
    dispatch(setMemberLoggedIn(userData?.data?.user?.role === "member"));
    dispatch(setGymOwnerLoggedIn(userData?.data?.user?.role === "partner"));
    dispatch(emptyBasket());
    await getUserMembershipStatusCall();

    if (userData?.data?.user?.role === "partner") {
      // Grab all partner gym information
      await getPartnerGymInfoCall();
    }
  } catch (error: any) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  }
};

export const loginWithApple = async () => {
  try {
    const result = await signInWithPopup(auth, appleProvider);

    // Apple credential
    const credential = OAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;
    // The signed-in user info.
    const user = result?.user;
    dispatch(setFirebaseUser(convertToSerializable(user)));

    const idToken = await getIdToken(user, true);
    dispatch(setIdToken(idToken));
    const userData = await getUser(idToken);

    if (token) dispatch(setLoggedIn(true));
    // IdP data available using getAdditionalUserInfo(result)
    // ...

    dispatch(setUserData(userData?.data?.user));
    dispatch(setMemberLoggedIn(userData?.data?.user?.role === "member"));
    dispatch(setGymOwnerLoggedIn(userData?.data?.user?.role === "partner"));
    dispatch(emptyBasket());
    await getUserMembershipStatusCall();

    if (userData?.data?.user?.role === "partner") {
      // Grab all partner gym information
      await getPartnerGymInfoCall();
    }
  } catch (error: any) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = OAuthProvider.credentialFromError(error);
    // ...
  }
};

export const resetPassFirebase = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
  }
};

export const logoutFirebase = async () => {
  try {
    await auth.signOut();
    dispatch(setLoggedIn(false));
    dispatch(setGymOwnerLoggedIn(false));
    dispatch(setMemberLoggedIn(false));
    dispatch(setIdToken(""));
    dispatch(setUserExist(false));
    dispatch(setFirebaseUser({}));
    dispatch(setMembershipUpgraded(false));
    dispatch(setBusinessProfile({}));
    dispatch(setBusinessDashboardData([]));
    dispatch(setPartnerGymInfo({}));
    dispatch(setUserData({}));
    dispatch(setIsNextGymSelected(false));
    dispatch(setUserCanDowngradeToBasic(false));
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
  }
};

export const getIdTokenFirebase = async () => {
  try {
    const idToken = await auth.currentUser?.getIdToken(true);
    dispatch(setIdToken(idToken));
    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
    return getIdTokenResult;
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
  }
};

export const deleteUserFirebase = async (firebaseUser: any) => {
  try {
    const result = await deleteUser(firebaseUser?.providerData?.[0]);
    // return "success";
    return result;
  } catch (error: any) {
    return error;
  }
};
