import styled from "styled-components";

import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

interface TileStatusProps {
  accountStatus: string;
}
interface TileTypeProps {
  membershipType?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center;
  background-color: ${templateColors.primaryBlack};
  width: 90%;
  margin-top: 5%;
  @media (max-width: 1224px) {
    width: 90%;
  }
`;

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.backgroundWhite};
  width: 80%;
  border-radius: 10px;
  padding: 3%;
  @media (max-width: 1224px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: ${templateColors.backgroundWhite};
  @media (max-width: 1224px) {
  }
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: 95%;
  border-radius: 10px;
  margin-top: 5%;
  padding: 3%;

  @media (max-width: 1224px) {
    width: 98%;
  }
`;

export const ScrollBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  height: 400px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px; /* Remove the scrollbar width */
    height: 0px; /* Remove the scrollbar height */
  }
  @media (max-width: 1224px) {
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1224px) {
  }
`;

export const Cell = styled.div`
  display: flex;
  background-color: ${colors.white};
  width: 140px;
  @media (max-width: 1224px) {
    width: auto;
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.title};
  font-weight: 600;

  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const Tile = styled.span`
  font-size: ${fontSizes.description};
  font-weight: 400;
  text-align: start;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 140px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    width: 50px;
    word-break: break-word;
  }
`;

export const TileTwo = styled.span`
  font-size: ${fontSizes.subtitleMobile};
  font-weight: 600;
  text-align: start;
  width: 150px;
  margin-bottom: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    width: 80px;
  }
`;

export const TileTree = styled.span`
  font-size: ${fontSizes.description};
  font-weight: 400;
  text-align: start;
  width: 250px;
  margin-bottom: 10px;
  margin-top: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    width: 80px;
  }
`;

export const TileStatus = styled.span<TileStatusProps>`
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: ${fontSizes.description};
  font-weight: 400;
  text-align: center;
  width: 95px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 100px;
  height: 32px;
  color: ${colors.white};
  background-color: ${(props) =>
    props.accountStatus === "true" ? colors.red : colors.green};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.xsMobile};
    width: 55px;
    height: 25px;
  }
`;

export const TileType = styled.span<TileTypeProps>`
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: ${fontSizes.description};
  font-weight: 500;
  text-align: center;
  width: 95px;
  margin-top: 16px;
  margin-bottom: 16px;
  border-radius: 100px;
  height: 32px;
  background-color: ${(props) =>
    props.membershipType === "basic"
      ? templateColors.primaryGrey
      : templateColors.lightViolet};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.xsMobile};
    width: 55px;
    height: 25px;
  }
`;
