import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import { GoogleAuthProvider } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCR_u_albIH67ustHlf-0nscD4hOq6Dxr8",
  authDomain: "jacked-rabbit-428100.firebaseapp.com",
  projectId: "jacked-rabbit-428100",
  storageBucket: "jacked-rabbit-428100.appspot.com",
  messagingSenderId: "145782451072",
  appId: "1:145782451072:web:0e53ec48b1d2086beaef65",
  measurementId: "G-G980S2TQCR",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: "select_account ",
});
