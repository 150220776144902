import styled from "styled-components";
import { styled as MUIstyled } from "@mui/material/styles";
import { TextField } from "@mui/material";

import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${templateColors.lightDark};
  max-width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px; /* Remove the scrollbar width */
    height: 0px; /* Remove the scrollbar height */
  }
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding-top: 2%;

  @media (max-width: 1224px) {
    width: 70%;
  }
`;

export const Title = styled.span`
  text-align: left;
  font-weight: 500;
  font-size: ${fontSizes.mediumTitle};
  color: ${colors.white};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.mediumTitleMobile};
  }
`;

export const LogoStyle = styled.img`
  width: 280px;
  height: 150px;
  object-fit: cover;
  @media (max-width: 1224px) {
    width: 200px;
    height: 150px;
  }
`;

export const CustomizedTextField = MUIstyled(TextField)`
  background-color: ${templateColors.lightDark};
  width: 100%;
  margin-top: 30px;

  & .MuiInputLabel-formControl {
    color: ${colors.white} !important;
  }

  & .MuiInput { 
    &::input {
      &::placeholder {
        color: ${colors.grey};
      }
    }
  }
  & .MuiOutlinedInput-root {
    & fieldset>legend {
      padding-right: 12px;
    }
    &.Mui-focused fieldset {
      border: 1px solid ${templateColors.lightYellow};
    }
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
  }

  & .MuiInputBase-root {
    color: ${colors.grey};
  }

  & .MuiOutlinedInput-root MuiInputBase {
    color:${colors.white};
  }

  & .MuiFormLabel-root-bhkJJT {
    color:${colors.white};
    font-size: ${fontSizes.subtitle};
    padding-right: 5px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
    :hover {
      border: 1px solid ${colors.white};
    }

    & .Mui-focused .MuiInputAdornment-root {
      border: 1px solid ${templateColors.lightYellow};
      
    }
  }
 
`;

export const CustomizedTextFieldEmail = MUIstyled(TextField)`
  background-color: ${templateColors.lightDark};
  width: 100%;
  margin-top: 30px;

  & .Mui-disabled {
    border: 1px solid ${templateColors.lightYellow};
    -webkit-text-fill-color: ${colors.grey} !important;
    color: ${colors.grey} !important;
  }

  & .doTUnX.Mui-disabled {
    -webkit-text-fill-color: ${colors.grey} !important;
    color: ${colors.grey} !important;
  }
  
  & .cMWUyC.Mui-disabled {
    opacity: 1;
    -webkit-text-fill-color: ${colors.grey} !important;
    color: ${colors.grey} !important;
  }
 
  & .MuiInputLabel-formControl {
    color: ${colors.white} !important;
  }

  & .MuiInput { 
    &::input {
      &::placeholder {
        color: ${colors.grey} !important;
        -webkit-text-fill-color: ${colors.grey} !important;
      }
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset>legend {
      padding-right: 12px;
    }
    &.Mui-focused fieldset {
      border: 1px solid ${templateColors.lightYellow};
    }
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
  }

  & .MuiInputBase-root {
    color: ${colors.grey} !important;
  }

  & .MuiOutlinedInput-root MuiInputBase {
    color:${colors.white};
  }

  & .MuiFormLabel-root-bhkJJT {
    color:${colors.white};
    font-size: ${fontSizes.subtitle};
    padding-right: 5px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
    :hover {
      border: 1px solid ${colors.white};
    }

    & .Mui-focused .MuiInputAdornment-root {
      border: 1px solid ${templateColors.lightYellow};
      
    }
  }
 
`;
