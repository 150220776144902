import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

interface ButtonProps {
  disabled?: boolean;
}

export const Container = styled.div``;

export const Form = styled.form``;

export const Tile = styled.span`
  color: ${theme.colors.white};
  font-size: ${fontSizes.description};
  font-weight: 400;
`;

export const Button = styled.button<ButtonProps>`
  background: ${(props) =>
    props.disabled
      ? theme.templateColors.buttonDisabled
      : theme.templateColors.lightYellow};

  border: none;
  width: 100%;
  height: 39px;
  margin-top: 30px;
  border-radius: 3px;
  color: ${theme.colors.black};
  font-size: ${fontSizes.subtitle};
  text-align: "center";
  cursor: pointer;

  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;
