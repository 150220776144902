import styled from "styled-components";

import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${templateColors.lightDark};
  border-radius: 12px;
  padding: 6%;

  @media (max-width: 1224px) {
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.title};
  font-weight: 700;
  color: ${colors.white};
  line-height: 1.5;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const Description = styled.span`
  font-size: ${fontSizes.subtitle};
  padding-top: 8%;
  color: ${colors.white};
  line-height: 1.6;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const Icon = styled.img`
  width: 140px;
  height: 140px;
  @media (max-width: 1224px) {
    width: 100px;
    height: 100px;
  }
`;
