import React from "react";

import ReactModal from "react-modal";

import { ContainerModal, Button, SubContainer } from "./styles";
import "./styles.css";
interface ModalProps {
  content: React.ReactNode;
  onClose?: (e: any) => void;
  backgroundColor: string;
  closeColor: string;
  showModal?: boolean;
  width?: string;
  padding?: string;
}

const Modal = ({
  content,
  onClose,
  backgroundColor = "white",
  closeColor,
  showModal = false,
  width = "350px",
  padding = "20px",
}: ModalProps) => {
  return (
    <ReactModal
      isOpen={showModal}
      className="modal"
      overlayClassName="modal-overlay"
    >
      <ContainerModal
        backgroundColor={backgroundColor}
        width={width}
        padding={padding}
      >
        <SubContainer>
          <Button
            color={closeColor}
            onClick={onClose}
            backgroundColor={backgroundColor}
          >
            &times;
          </Button>
        </SubContainer>
        {content}
      </ContainerModal>
    </ReactModal>
  );
};

export default Modal;
