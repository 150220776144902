import styled from "styled-components";
import TextField from "@mui/material/TextField";

import { styled as MUIstyled } from "@mui/material/styles";
import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${templateColors.lightDark};
  width: 80%;
  margin-top: 10%;
  border-radius: 12px;
  @media (max-width: 1224px) {
    width: 90%;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 82%;
  border-radius: 12px;
  padding-left: 5%;
  padding-bottom: 3%;
  background-color: ${templateColors.primaryBlack};
  @media (max-width: 1224px) {
  }
`;

export const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background-color: ${templateColors.lightDark};
  height: 280px;
  width: 250px;
  border-radius: 12px;
  cursor: pointer;
  @media (max-width: 1224px) {
    margin-top: 10px;
  }
`;

export const ImageBoxSub = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background-color: ${templateColors.lightDark};
  height: 250px;
  width: 240px;
  border-radius: 12px;

  cursor: pointer;
  @media (max-width: 1224px) {
  }
`;

export const Image = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;
  cursor: pointer;
  border-radius: 3px;
  @media (max-width: 1224px) {
  }
`;

export const SubImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${templateColors.primaryBlack};
  width: 95%;
  @media (max-width: 1224px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export const Close = styled.div`
  color: ${colors.darkGrey};
  font-size: 17px;
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 15px;
  margin-top: 5px;
  @media (max-width: 1224px) {
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.subtitle};
  font-weight: 500;
  color: ${colors.white};
  margin-top: 3%;
  margin-bottom: 3%;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const ImagesTitle = styled.span`
  font-size: ${fontSizes.subtitle};
  font-weight: 500;
  color: ${colors.white};
  background-color: ${templateColors.primaryBlack};
  margin-top: 3%;
  margin-bottom: 3%;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 5%;
  @media (max-width: 1224px) {
  }
`;

export const SubContainerForm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 5%;
  @media (max-width: 1224px) {
    flex-direction: column;
  }
`;
export const RightForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-right: 5%;
  @media (max-width: 1224px) {
    width: 90%;
    padding-left: 5%;
  }
`;
export const LeftForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-right: 50px;
  padding-left: 5%;
  @media (max-width: 1224px) {
    width: 90%;
  }
`;

export const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 86%;
  border-radius: 6px;
  padding-bottom: 3%;
  padding-left: 4%;
  padding-right: 3%;
  background-color: ${templateColors.primaryBlack};
  @media (max-width: 1224px) {
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 93%;
`;

export const Types = styled.span`
  font-size: ${fontSizes.description};
  margin-left: 30px;
  padding-bottom: 4px;
  color: ${templateColors.lightYellow};
`;

export const CustomizedTextField = MUIstyled(TextField)`
  background-color: ${templateColors.lightDark};
  margin-top: 30px;

  & .MuiInputLabel-formControl {
    color: ${colors.white} !important;
  }

  & .MuiInput { 
    &::input {
      &::placeholder {
        color: ${colors.grey};
      }
    }
  }

  & .MuiOutlinedInput-root {
    & fieldset>legend {
      padding-right: 15px;
    }
    &.Mui-focused fieldset {
      border: 1px solid ${templateColors.lightYellow};
    }
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
  }

  & .MuiInputBase-root {
    color: ${colors.grey};
  }
 
  & .MuiOutlinedInput-root MuiInputBase {
    color: ${colors.white};
  }

  & .MuiFormLabel-root-bhkJJT {
    color: ${colors.grey};
    font-size: ${fontSizes.subtitle};
    padding-right: 5px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${templateColors.lightYellow};
    :hover {
      border: 1px solid ${colors.white};
    }

    & .Mui-focused .MuiInputAdornment-root {
      border: 1px solid ${templateColors.lightYellow};
      
    }
  }
 
`;
