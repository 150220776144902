import React from "react";

import {
  HOW_IT_WORKS_TITLE_PART_1,
  HOW_IT_WORKS_TITLE_PART_2,
  HOW_IT_WORKS_SUBTITLE,
} from "../../../utils/strings";
import { howItWorksCardArr } from "../../../utils/arrays";
import Card from "../../../components/Card";
import {
  Container,
  TopContainer,
  Title,
  Subtitle,
  BottomContainer,
} from "./styles";
import { isTabletOrMobileSize } from "../../../utils/queries";
import { useMediaQuery } from "react-responsive";

const HowItWorksSection = () => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  return (
    <Container>
      <TopContainer>
        <Title style={{ color: "white" }}>{HOW_IT_WORKS_TITLE_PART_1}</Title>
        {!isTabletOrMobile && (
          <Title style={{ color: "white" }}>{HOW_IT_WORKS_TITLE_PART_2}</Title>
        )}
      </TopContainer>
      <BottomContainer>
        {howItWorksCardArr?.map(({ title, description, descriptionMobile }) => (
          <Card
            key={title}
            id={title}
            title={title}
            description={isTabletOrMobile ? descriptionMobile : description}
          />
        ))}
      </BottomContainer>
    </Container>
  );
};

export default HowItWorksSection;
