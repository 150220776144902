import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${templateColors.backgroundWhite};
  width: 100%;
  padding-top: 4%;
  font-family: "Inter";
  @media (max-width: 1224px) {
    padding-top: 8%;
  }
`;

export const PartnersSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const TopContainerPartners = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  @media (max-width: 1224px) {
    width: 80%;
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.bigTitle};
  font-weight: 700;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.bigTitleMobile};
  }
`;

export const Subtitle = styled.span`
  font-size: ${fontSizes.title};
  padding-top: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const GymLogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  gap: 80px;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 1224px) {
    flex-wrap: wrap;
    gap: 50px;
    width: 70%;
  }
`;

export const Logo = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
  @media (max-width: 1224px) {
    height: 80px;
    width: 80px;
  }
`;

export const Image = styled.img`
  height: 600px;
  width: 600px;
  @media (max-width: 1224px) {
    height: 350px;
    width: 350px;
  }
`;

export const ListYourGymSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 8%;
  @media (max-width: 1224px) {
    flex-direction: column-reverse;
    padding-top: 16%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 2%;
  @media (max-width: 1224px) {
    width: 80%;
    justify-content: center;
    align-self: center;
  }
`;

export const TitleListYourGym = styled.span`
  font-size: ${fontSizes.mediumTitle};
  font-weight: 500;
  padding-bottom: 5%;

  &::after {
    content: " Jacked Rabbit Partner.";
    font-size: ${fontSizes.mediumTitle};
    font-weight: 700;

    @media (max-width: 1224px) {
      font-size: ${fontSizes.mediumTitleMobile};
    }
  }

  @media (max-width: 1224px) {
    font-size: ${fontSizes.mediumTitleMobile};
  }
`;

export const Description = styled.span`
  font-size: ${fontSizes.subtitle};
  line-height: 40px;
  padding-top: 2%;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    line-height: 30px;
  }
`;

export const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: 1224px) {
    width: 95%;
    margin-top: 5%;
  }
`;
