import styled from "styled-components";

import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 2%;
  @media (max-width: 1224px) {
    flex-wrap: wrap;
    margin-top: 5%;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: flex-start;
  cursor: pointer;
  width: 80px;
  @media (max-width: 1224px) {
    margin-top: 5%;
  }
`;

export const Icon = styled.img<{ selected: boolean }>`
  width: 30px;
  height: 30px;
  margin-bottom: 7px;
  filter: ${(props) =>
    props.selected
      ? "none"
      : "invert(81%) sepia(8%) saturate(2204%) hue-rotate(13deg) brightness(101%) contrast(101%)"};
  @media (max-width: 1224px) {
    width: 20px;
    height: 20px;
  }
`;

export const Tile = styled.span`
  font-size: ${fontSizes.xs};
  text-align: center;
  color: ${templateColors.lightGrey};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.xsMobile};
  }
`;
