import React from "react";

import { useSelector } from "react-redux";

import {
  SUMMARY,
  TOTAL,
  EMPTY_CART,
  TRANSACTION_FEE,
  TAX,
  MEMBERSHIP_SUBSCRIPTION,
} from "../../utils/strings";
import AmenitiesIconList from "../AmenitiesIconList";
import Line from "../Line";
import { Address as AddressImage } from "../../assets/images";
import {
  Container,
  Title,
  SubContainer,
  Subtitle,
  Description,
  AmenitiesContainer,
  Address,
  SocialIconsContainer,
  SocialIconRef,
  SocialIcon,
  TilePurchaseBox,
  Tile,
  ValueTile,
  AddressIcon,
  AddressContainer,
} from "./styles";

const SummaryCard = () => {
  const { basketProduct, selectedProduct, businessProfile } = useSelector(
    (state: RootState) => state.appData
  );

  const { name, description, amenities, address, socialLinks }: any =
    (basketProduct || businessProfile) ?? {
      name: "",
      description: "",
      amenities: [],
      address: { fullAddress: "" },
      socialLinks: [],
      products: [],
    };

  const { price } = selectedProduct || {};
  const { feeAmount, preTaxCost, taxAmount, total } = price || {};

  return (
    <Container>
      <Title> {SUMMARY}</Title>
      <SubContainer>
        {basketProduct || selectedProduct ? (
          <>
            <Subtitle>{name}</Subtitle>
            <Description>{description}</Description>
            <AmenitiesContainer>
              <AmenitiesIconList amenities={amenities} />
            </AmenitiesContainer>
            <AddressContainer>
              <AddressIcon src={AddressImage} />
              {address && (
                <Address
                  href={`http://maps.google.com/?q=${address?.fullAddress}`}
                  target="_blank"
                  rel="noopener"
                >
                  {address?.fullAddress}
                </Address>
              )}
            </AddressContainer>
            <SocialIconsContainer>
              {socialLinks?.map(({ name, socialLink }: any) => {
                return (
                  name &&
                  socialLink && (
                    <SocialIconRef
                      href={socialLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={name}
                    >
                      <SocialIcon
                        src={require(`../../assets/images/${name}.svg`)}
                      />
                    </SocialIconRef>
                  )
                );
              })}
            </SocialIconsContainer>
            <Line />
            <TilePurchaseBox>
              <Tile>{MEMBERSHIP_SUBSCRIPTION}</Tile>
              {preTaxCost && <ValueTile>$ {preTaxCost}</ValueTile>}
            </TilePurchaseBox>
            <TilePurchaseBox>
              <Tile>{TAX}</Tile>
              {taxAmount && <ValueTile>$ {taxAmount}</ValueTile>}
            </TilePurchaseBox>
            <TilePurchaseBox>
              <Tile>{TRANSACTION_FEE}</Tile>
              {feeAmount && <ValueTile>$ {feeAmount}</ValueTile>}
            </TilePurchaseBox>
            <Line />
            <TilePurchaseBox>
              <Tile>{TOTAL}</Tile>
              {total && <ValueTile>$ {total}</ValueTile>}
            </TilePurchaseBox>
          </>
        ) : (
          <Subtitle>{EMPTY_CART}</Subtitle>
        )}
      </SubContainer>
    </Container>
  );
};

export default SummaryCard;
