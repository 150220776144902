import { useEffect, useState } from "react";

import "./styles.css";
import { LEARN_MORE } from "../../utils/strings";
import { Gallery } from "../../utils/types";

interface EOMImageCarouselProps {
  gallery: Gallery[];
  title?: string;
  handleOpen?: any;
}

const EOMImageCarousel = ({
  gallery,
  title,
  handleOpen,
}: EOMImageCarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [imageSrc, setImageSrc] = useState<string>("");

  const swipeImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === gallery?.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="carousel">
      <img
        src={`${gallery?.[currentIndex]?.imageUrl}`}
        alt="carousel"
        className="carousel-image"
        onClick={swipeImage}
      />
      {title && (
        <div className="badge" onClick={handleOpen}>
          <span className="badge-title">{title}</span>
          <span className="badge-subtitle">{LEARN_MORE}</span>
        </div>
      )}
    </div>
  );
};

export default EOMImageCarousel;
