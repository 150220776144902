import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ArrowRightYellow } from "../../assets/images";
import { setSelectedProduct } from "../../slices/appSlice";
import { filterByName } from "../../utils/utils";
import { upgradeToPremium } from "../../api/apiEndpoints";
import Modal from "../Modal";
import StatusScreen from "../StatusScreen";
import {
  CONFIRM,
  CONGRATS,
  IN_YOUR_UPCOMING,
  MEMBERSHIP_TYPE,
  MEMBERSHIP_UPGRADED,
  NEXT_GYM,
  PAYMENT_SUCCESS,
  PLEASE_SELECT_YOUR_NEXT,
  SELECTION_PENDING,
  WE_COULDNT_PROCCESS_PAYMENT,
  YOUR_ARE_ABOUT_TO_UPGRADE,
} from "../../utils/strings";
import theme from "../../utils/theme";
import { getUserMembershipStatusCall } from "../../api/apiOperations";
import {
  Container,
  Tile,
  SubTile,
  Title,
  Row,
  Description,
  DescriptionTwo,
  BottomContainer,
  BottomTile,
  Image,
  DescriptionThree,
  RowTwo,
  RowThree,
  RightContainer,
  LeftContainer,
  SubContainer,
} from "./styles";
import EOMImageCarousel from "../EOMImageCarousel";

interface InfoCardProps {
  tileOne: string;
  subtile: string;
  title: string;
  description: string;
  descriptionTwo?: string;
  descriptionThree?: string;
  bottomTile: string;
  upgradeMembershipPrice: number;
}

const InfoCard = ({
  tileOne,
  subtile,
  title,
  description,
  descriptionTwo,
  descriptionThree,
  bottomTile,
  upgradeMembershipPrice,
}: InfoCardProps) => {
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [paymentFailure, setPaymentFailure] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [showSummaryBox, setShowSummaryBox] = useState<boolean>(true);

  const dispatch = useDispatch();

  const {
    selectedProduct,
    businessProfile,
    isNextGymSelected,
    userMembershipStatus,
    membershipUpgraded,
  } = useSelector((state: RootState) => state.appData);
  const { idToken } = useSelector((state: RootState) => state.auth);

  const upgradeMembershipAmount = filterByName(
    businessProfile?.products,
    "premium"
  );

  const { nextBusinessName } = userMembershipStatus?.settings || {};

  const notFailureTitle = paymentSuccess
    ? MEMBERSHIP_UPGRADED
    : YOUR_ARE_ABOUT_TO_UPGRADE;
  const paymentInfo = {
    upgradeMembershipPrice: upgradeMembershipPrice?.toFixed(2),
    taxAmount: upgradeMembershipAmount?.price?.taxAmount,
    feeAmount: upgradeMembershipAmount?.price?.feeAmount,
    total: upgradeMembershipAmount?.price?.total,
    preTaxCost: upgradeMembershipAmount?.price?.preTaxCost,
  };

  const handleUpgradeButton = () => {
    if (!membershipUpgraded) {
      setShowStatusModal(true);
      dispatch(setSelectedProduct(upgradeMembershipAmount));
    }
  };

  const handleUpgradeMembership = async () => {
    setLoading(true);
    if (idToken && selectedProduct) {
      const result = await upgradeToPremium(selectedProduct?.businessProductId);

      if (result.status === 200) {
        setLoading(false);
        setPaymentSuccess(true);
        setShowSummaryBox(false);
        await getUserMembershipStatusCall();
      } else {
        setLoading(false);
        setPaymentFailure(true);
        setShowSummaryBox(false);
      }
    }
  };

  const handleOpenStatusModal = () => {
    if (paymentSuccess) {
      setPaymentSuccess(false);
    }
    setShowStatusModal(!showStatusModal);
  };

  return (
    <Container>
      <SubContainer>
        <LeftContainer>
          <Tile>{tileOne}</Tile>
          <SubTile>{subtile}</SubTile>
          <Title>{title}</Title>
          <Row>
            <Description>{`${MEMBERSHIP_TYPE}:`}</Description>
            <DescriptionTwo>
              {descriptionTwo === "basic" ? "Hopper" : "Power"}
            </DescriptionTwo>
          </Row>
          <Row>
            <Description>{description}</Description>
            <DescriptionTwo>{descriptionThree}</DescriptionTwo>
          </Row>

          <RowThree>
            <Description>{NEXT_GYM}</Description>
            <DescriptionTwo
              style={{
                fontStyle: isNextGymSelected ? "normal" : "italic",
              }}
            >
              {!isNextGymSelected && !membershipUpgraded && SELECTION_PENDING}
              {isNextGymSelected && !membershipUpgraded && nextBusinessName}
              {membershipUpgraded && `${tileOne} (Upgraded to Power) `}
            </DescriptionTwo>
          </RowThree>
          {!isNextGymSelected && !membershipUpgraded && (
            <RowTwo>
              <DescriptionThree>{PLEASE_SELECT_YOUR_NEXT}</DescriptionThree>
            </RowTwo>
          )}
        </LeftContainer>
        <RightContainer>
          <EOMImageCarousel gallery={businessProfile?.gallery} />
        </RightContainer>
      </SubContainer>
      <BottomContainer onClick={handleUpgradeButton}>
        <BottomTile>{bottomTile}</BottomTile>
        {!membershipUpgraded && <Image src={ArrowRightYellow} />}
      </BottomContainer>
      <Modal
        showModal={showStatusModal}
        onClose={handleOpenStatusModal}
        content={
          <StatusScreen
            showIcon={false}
            title={
              paymentFailure ? WE_COULDNT_PROCCESS_PAYMENT : notFailureTitle
            }
            showButton={paymentSuccess ? false : true}
            successButtonTile={CONFIRM}
            failure={paymentFailure}
            loading={loading}
            onClose={handleOpenStatusModal}
            onButtonClick={handleUpgradeMembership}
            useButtonEvent={paymentSuccess ? false : true}
            info={paymentInfo}
            showSummary={showSummaryBox}
          />
        }
        backgroundColor={theme.templateColors.lightDark}
        closeColor={theme.templateColors.lightYellow}
      />
    </Container>
  );
};

export default InfoCard;
