import styled from "styled-components";

import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${templateColors.lightDark};
  border-radius: 12px;
  padding: 7% 10% 10% 10%;
  margin-right: 7%;
  margin-left: 7%;
  @media (max-width: 1224px) {
    margin-right: 0%;
    margin-left: 0%;
    width: 75%;
    padding: 7%;
  }
`;

export const Title = styled.span`
  color: ${colors.white};
  font-weight: 600;
  font-size: ${fontSizes.subtitle};
  margin-bottom: 40px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    font-weight: 600;
    margin-bottom: 20px;
  }
`;

export const Subtitle = styled.span`
  color: ${colors.white};
  font-weight: 600;
  font-size: ${fontSizes.subtitle};
  margin-bottom: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.subtitleMobile};
    font-weight: 600;
  }
`;

export const Description = styled.span`
  color: ${colors.white};
  font-size: ${fontSizes.description};
  margin-bottom: 10px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.description};
  }
`;

export const Address = styled.a`
  color: ${templateColors.lightYellow};
  font-size: 9px;
  line-height: 12px;
  text-decoration: none;
  @media (max-width: 1224px) {
  }
`;

export const ValueTile = styled.span`
  color: ${colors.white};
  font-weight: 600;
  font-size: ${fontSizes.description};
  padding-left: 4px;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const Tile = styled.span`
  color: ${colors.white};
  font-weight: 600;
  font-size: ${fontSizes.description};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  width: 100%;
`;

export const AddressContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 3px;
  @media (max-width: 1224px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const BriefSummary = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1%;
  width: 80%;
`;

export const TilePurchaseBox = styled.div`
  margin-top: 13px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SocialIcon = styled.img`
  background-size: 14px 14px;
  margin-right: 8px;

  @media (max-width: 1224px) {
    background-size: 11px 11px;
    margin-right: 8px;
  }
`;

export const AddressIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-right: 10px;

  @media (max-width: 1224px) {
    width: 13px;
    height: 13px;
    margin-right: 8px;
  }
`;

export const SocialIconRef = styled.a`
  display: flex;
  align-items: center;
`;
