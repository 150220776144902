/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthSliceState {
  idToken?: string | null;
  loggedIn: boolean;
  gymOwnerLoggedIn: boolean;
  memberLoggedIn: boolean;
}

const authInitialState: AuthSliceState = {
  idToken: null,
  loggedIn: false,
  gymOwnerLoggedIn: false,
  memberLoggedIn: false,
};

const auth = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    setIdToken(state, { payload }: PayloadAction<any>) {
      state.idToken = payload;
    },
    setLoggedIn(state, { payload }: PayloadAction<any>) {
      state.loggedIn = payload;
    },
    setGymOwnerLoggedIn(state, { payload }: PayloadAction<any>) {
      state.gymOwnerLoggedIn = payload;
    },
    setMemberLoggedIn(state, { payload }: PayloadAction<any>) {
      state.memberLoggedIn = payload;
    },
  },
});

export const {
  setIdToken,
  setLoggedIn,
  setGymOwnerLoggedIn,
  setMemberLoggedIn,
} = auth.actions;

export default auth.reducer;
