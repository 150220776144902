import React from "react";

import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import EOMButton from "../EOMButton";
import {
  MEMBERS_LIST,
  NO_MEMBERS_TO_SHOW,
  USER,
  EMAIL,
  MEMBERSHIP_EXPIRATION,
  ACCOUNT_STATUS,
  MEMBERSHIP_TYPE,
  CREATE_PROFILE_CARD,
  PROFILE_CARD_CREATED,
} from "../../utils/strings";
import { isTabletOrMobileSize } from "../../utils/queries";
import theme from "../../utils/theme";
import Line from "../Line";
import { BusinessDashboardUser } from "../../utils/types";
import { daysUntilExpiration, isMembershipCancelled } from "../../utils/utils";
import {
  Container,
  Background,
  Title,
  Subcontainer,
  Header,
  Tile,
  Row,
  TileTwo,
  ScrollBox,
  TileTree,
  TileStatus,
  TileType,
  Cell,
} from "./styles";

interface DashboardListingProps {
  handleOpenGymCardForm: () => void;
}

const DashboardListing = ({ handleOpenGymCardForm }: DashboardListingProps) => {
  const isTabletOrMobile = useMediaQuery({ query: isTabletOrMobileSize });

  const { businessDashboardData, partnerGymInfo } = useSelector(
    (state: RootState) => state.appData || {}
  );

  const profileCardCreated = partnerGymInfo?.products?.length > 1;

  return (
    <Container>
      <Background>
        <Header>
          <Title>{MEMBERS_LIST}</Title>
          <EOMButton
            title={
              profileCardCreated ? PROFILE_CARD_CREATED : CREATE_PROFILE_CARD
            }
            onPress={profileCardCreated ? () => null : handleOpenGymCardForm}
            buttonStyle={{
              backgroundColor: theme.templateColors.lightYellow,
              borderRadius: 3,
              width: isTabletOrMobile ? 110 : 150,
            }}
            titleStyle={{
              color: "black",
              fontSize: isTabletOrMobile ? 10 : 13,
              textAlign: "center",
            }}
          />
        </Header>
        <Subcontainer>
          <Row>
            <TileTwo>{USER}</TileTwo>
            <TileTwo>{EMAIL}</TileTwo>
            <TileTwo>{MEMBERSHIP_EXPIRATION}</TileTwo>
            <TileTwo>{ACCOUNT_STATUS}</TileTwo>
            <TileTwo>{MEMBERSHIP_TYPE}</TileTwo>
          </Row>
          {businessDashboardData?.length > 0 ? (
            <ScrollBox>
              {businessDashboardData?.map(
                ({
                  firstName,
                  lastName,
                  email,
                  expiresAt,
                  productName,
                  scheduledCancelAt,
                }: BusinessDashboardUser) => (
                  <div key={uuidv4()}>
                    <Line />
                    <Row key={uuidv4()}>
                      <Cell>
                        <Tile>
                          {firstName} {lastName}
                        </Tile>
                      </Cell>
                      <Cell>
                        <Tile>{email}</Tile>
                      </Cell>
                      <Cell>
                        <Tile>
                          {!isMembershipCancelled(scheduledCancelAt)
                            ? daysUntilExpiration(expiresAt)
                            : "-"}
                        </Tile>
                      </Cell>
                      <Cell>
                        <TileStatus
                          accountStatus={
                            isMembershipCancelled(scheduledCancelAt)
                              ? "true"
                              : "false"
                          }
                        >
                          {isMembershipCancelled(scheduledCancelAt)
                            ? "Disabled"
                            : "Active"}
                        </TileStatus>
                      </Cell>
                      <Cell>
                        <TileType membershipType={productName}>
                          {productName === "basic" ? "Hopper" : "Power"}
                        </TileType>
                      </Cell>
                    </Row>
                  </div>
                )
              )}
            </ScrollBox>
          ) : (
            <>
              <Line />
              <TileTree>{NO_MEMBERS_TO_SHOW}</TileTree>
            </>
          )}
        </Subcontainer>
      </Background>
    </Container>
  );
};

export default DashboardListing;
