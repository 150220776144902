import styled from "styled-components";

import theme from "../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${templateColors.primaryBlack};
  width: 100%;
  padding: 4% 3% 3% 3%;
  @media (max-width: 1224px) {
    padding: 10% 0% 0% 0%;
    margin-bottom: 9%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  background-color: ${templateColors.primaryBlack};
  width: 50%;
  justify-content: flex-end;
  padding-top: 1%;
  padding-right: 3%;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  justify-content: flex-end;
  background-color: ${templateColors.primaryBlack};
  width: 50%;

  @media (max-width: 1224px) {
    padding-left: 8%;
  }
`;

export const Subtitle = styled.span`
  font-size: ${fontSizes.description};
  padding-top: 10px;
  color: ${templateColors.lightGrey};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const Title = styled.span`
  font-size: ${fontSizes.title};
  padding-top: 10px;
  font-weight: 700;
  color: ${colors.white};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.titleMobile};
  }
`;

export const Image = styled.img`
  height: 60px;
  width: 60px;
  cursor: pointer;
  @media (max-width: 1224px) {
    height: 40px;
    width: 40px;
  }
`;
