import styled from "styled-components";
import theme from "../../../utils/theme";

const { colors, templateColors, fontSizes } = theme;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${templateColors.lightYellow};
  height: 260px;
  width: 100%;
  margin-top: 5%;
  padding-top: 2%;
  font-family: "Inter";
  @media (max-width: 1224px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-top: 20%;
    max-width: 100%;
    overflow-x: hidden;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: space-between;
  width: 25%;
  padding-top: 1%;
  padding-right: 5%;

  @media (max-width: 1224px) {
    width: 100%;
    padding-right: 0px;
    align-items: flex-start;
    margin-left: 5%;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${templateColors.lightYellow};
  width: 75%;
  padding-top: 3%;
  @media (max-width: 1224px) {
    width: 100%;
    align-items: center;
    padding-top: 10%;
  }
`;

export const PageSectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${templateColors.lightYellow};
  width: 80%;
  padding-left: 5%;
  gap: 30px;
  @media (max-width: 1224px) {
    justify-content: flex-start;
    align-self: flex-start;
    gap: 0px;
    flex-direction: column;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SocialMediaBox = styled.div`
  display: flex;
  align-self: flex-end;
  @media (max-width: 1224px) {
    align-self: flex-start;
    margin-left: 2%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding-right: 3%;
  padding-bottom: 2%;
  @media (max-width: 1224px) {
    flex-direction: row;
    padding-right: 0%;
    padding-left: 10%;
    padding-top: 20%;
  }
`;

export const EOMBox = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  @media (max-width: 1224px) {
    align-items: center;
  }
`;

export const Ahref = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  z-index: 200;
`;

export const TileRight = styled.span`
  font-size: 12px;
  font-weight: 600;
  padding-top: 75px;
  @media (max-width: 1224px) {
    font-size: 10px;
    position: absolute;
    bottom: 0;
    padding-bottom: 2%;
  }
`;

export const Tile = styled.span`
  font-size: ${fontSizes.description};
  color: ${colors.black};
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
  }
`;

export const TileEOM = styled.span`
  color: ${colors.black};
  font-weight: 800;
  margin-left: 8px;
  font-size: 14px;
  text-decoration: underline;
  @media (max-width: 1224px) {
    font-size: 10px;
  }
`;

export const SocialIcon = styled.img`
  @media (max-width: 1224px) {
    width: 30px;
    height: 30px;
  }
`;

export const Logo = styled.img`
  width: 170px;
  height: 50px;
  object-fit: cover;
  margin-top: -15px;

  @media (max-width: 1224px) {
    width: 140px;
    height: 80px;
  }
`;

export const EOMLogo = styled.img`
  margin-top: 4px;
  @media (max-width: 1224px) {
    width: 18px;
    height: 18px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1224px) {
    width: 50%;
    padding-left: 10px;
  }
`;

export const ColumnsLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 1224px) {
    width: 100%;
    gap: 0px;
  }
`;

export const ColumnsRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 1224px) {
    width: 100%;
    gap: 0px;
  }
`;

export const ColumnTitle = styled.span`
  font-weight: 700;
  font-size: 15px;
  padding-bottom: 20px;
  @media (max-width: 1224px) {
    font-size: 12px;
    padding-top: 20px;
  }
`;

export const ColumnTile = styled.span`
  font-size: ${fontSizes.description};
  padding-bottom: 20px;
  cursor: pointer;
  @media (max-width: 1224px) {
    font-size: ${fontSizes.descriptionMobile};
    padding-bottom: 5px;
  }
`;
